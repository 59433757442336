import React, { useEffect, useState } from 'react';
import { StudentsAbsenceTable } from './students-absence-table';
import { IStudentService, StudentService } from '../../services/student-service';
import { ISearchStudentWithMemorization } from '../../models/search-student-with-memorization';

export function StudentsAbsenceContainer() {

  const [studentsWithMemorizationState, setStudentsWithMemorization] = useState({
    studentWithMemorization: [] as ISearchStudentWithMemorization[],
  });

  const [isLoading, setIsLoading] = useState(false);
  
  const studentService: IStudentService = new StudentService();

  const handleSearchStudents = async (startDate: string, endDate: string)=>{
   
    setIsLoading(true);

    await studentService
    .getStudentWithMemorizationsByDateInterval(startDate, endDate)
    .then((data) => {
      if (data) {
        setStudentsWithMemorization({ studentWithMemorization: data });
      }

      setIsLoading(false);
    });
  }

  return (
    <>
      <StudentsAbsenceTable 
          studentWithMemorization={studentsWithMemorizationState.studentWithMemorization} 
          onSearchStudents={handleSearchStudents}
          isLoading={isLoading}/>
    </>
  );
}

