import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { MemorizationType } from "../../enums/status-enums";
import { IsMemorizationClosedForChanges } from "../../helpers/date-helper";
import { SortMemorizationType } from "../../helpers/sort-helper";
import { IMemorization } from "../../models/memorization";
import { IMemorizationChapter } from "../../models/memorization-chapter";
import MemorizationChapterModal from "./memorization-chapter-modal";

export interface IMemorizationChapterTable{
  memorizationChapters: IMemorizationChapter[];
  memorization?: IMemorization;
  onChangeMemorizationChapter: (memorizationChapter: IMemorizationChapter)=> void;
}

export function MemorizationChapterTable(props: IMemorizationChapterTable){

  const {memorizationChapters, memorization, onChangeMemorizationChapter} = props;

  const [openModal, setOpenModal] = useState(false);

  const [memorizationChapterRow, setMemorizationChapterRow] = useState({
    memorizationChapter: {} as IMemorizationChapter
  });

  const [memorizationChaptersState, setMemorizationChapters] = useState(memorizationChapters 
    ? memorizationChapters : [] as IMemorizationChapter[] );

  useEffect(()=>{
    if(memorizationChapters){
      setMemorizationChapters(memorizationChapters);
    }
  },[memorizationChapters]);

  const rowClick = (memorizationChapter: IMemorizationChapter) => {

     if(memorization && IsMemorizationClosedForChanges(memorization.completedDate) )
     {
        return;
     }
     
     setOpenModal(true);
     setMemorizationChapterRow({
      memorizationChapter: memorizationChaptersState.
      filter(mc=> mc.chapterId === memorizationChapter.chapterId && mc.memorizationType === memorizationChapter.memorizationType)[0]});
  }

  const handleOnHideModal = () => {
    setOpenModal(false);
  }

  const handleChangeMemorizationChapter = (memorizationChapter: IMemorizationChapter) => {
    onChangeMemorizationChapter(memorizationChapter);
  }

  return (
    <>
           {memorizationChapterRow.memorizationChapter && memorizationChapterRow.memorizationChapter.chapterId &&
            <MemorizationChapterModal showModal={openModal} 
              memorizationChapter={memorizationChapterRow.memorizationChapter}
              onHideModal={handleOnHideModal}
              onChangeMemorizationChapter ={handleChangeMemorizationChapter}/>
           }
           {memorizationChaptersState.length > 0 &&  <Table striped bordered hover>
            <thead>
              <tr>
                <th>Surah</th>
                <th>From</th>
                <th>To</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
            {memorizationChaptersState.sort(SortMemorizationType).map((memorizationChapter, index) => (
                      <tr  onClick={()=> rowClick(memorizationChapter)} key={index}>
                          <td>
                            {memorizationChapter.chapterDisplayName}
                          </td>
                          <td>
                            {memorizationChapter.fromAyah}
                          </td>
                          <td>
                            {memorizationChapter.toAyah}
                          </td>
                          <td>
                            {memorizationChapter.memorizationType === MemorizationType.Hifz ? "Hifz" : "Murajara"}
                          </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
         } 
       
    </>
  );
}

