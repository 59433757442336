import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from "react-bootstrap";
import { ISearchStudentWithMemorization } from "../../models/search-student-with-memorization";
import { GetCurrentDate,  GetDate,  GetDateString } from "../../helpers/date-helper";
import { v4 as uuidv4 } from 'uuid';
import { CreateExcelFile } from "../../excel/excel-download";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface IStudentAbsenceTableErrors{
  startDateError: string;
  endDateError: string;
  startDateBiggerThanEndDateError: string;
}

export interface IStudentAbsenceTable{
    studentWithMemorization: ISearchStudentWithMemorization[];
    onSearchStudents: (startDate: string, endDate: string)=> void;
    isLoading: boolean;
}

export function StudentsAbsenceTable(props: IStudentAbsenceTable){

  const {studentWithMemorization, onSearchStudents, isLoading } = props;

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(GetCurrentDate());
  const [selectedEndDate, setSelectedEndDate] = useState<Date>(GetCurrentDate());
  const [rowsState, setRowsState] = useState({
    rows: [] as any[]
  });

  const [errorState, setErrorState] = useState({
    error: { } as IStudentAbsenceTableErrors
   });

  const [studentMemorizationFlatStructureState, setStudentMemorizationFlatStructure] = useState({
    studentMemorization: [] as any[],
  });


  useEffect(()=>{
    setRowsState({rows: rows()})
  },[studentWithMemorization]);

  const handleStartDateChange = (date: Date) => {
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date: Date) => {
     setSelectedEndDate(date);
  };

  const handleSearchStudents = ()=> {

    if(hasError()) return;

    if(selectedStartDate && selectedEndDate)
    {
       onSearchStudents(GetDateString(selectedStartDate), GetDateString(selectedEndDate));
    }

    setErrorState({error: { } as IStudentAbsenceTableErrors});
  }

  const handleDownloadExcel = ()=> {
    CreateExcelFile(studentMemorizationFlatStructureState.studentMemorization);
  }

  const hasError = ()=> {

    const errors = {error: {
      ...errorState.error, 
       startDateError: !selectedStartDate ? "Startdate is required" : "",
       endDateError: !selectedEndDate ? "Enddate is required" : "",
       startDateBiggerThanEndDateError: GetDate(selectedStartDate) > GetDate(selectedEndDate) ? "Startdate must be lesser than end date" : ""
     }};

    setErrorState(errors);

    if(errors.error.startDateError || errors.error.endDateError || errors.error.startDateBiggerThanEndDateError){
      return true;
    }

    return false;
  }

  const getDaysFromDates = ()=> {

    const startDateTime = (selectedStartDate ? selectedStartDate.getTime() : 0);
    const endDateTime = (selectedEndDate ? selectedEndDate.getTime() : 0);

    if(endDateTime === 0) return 1;

    var timeDiff = endDateTime - startDateTime;

    // Convert milliseconds to days
    var daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    return daysDiff <= 0 ? 1 : daysDiff + 1;
  }

  const groups: { [key: string]: ISearchStudentWithMemorization[] } = studentWithMemorization && studentWithMemorization.length > 0 &&
   studentWithMemorization.reduce((acc:any, person) => {
    const studentId = person.student.studentId;
    if (!acc[studentId]) {
      acc[studentId] = [];
    }
    acc[studentId].push(person);
    return acc;
  }, {});

  const rows = ()=>{
      let rows = [] as any[];

      if(!studentWithMemorization || studentWithMemorization.length === 0) return rows;

      let studentMemorizationFlatStructure: any[] = [];

      Object.values(groups).forEach((group: ISearchStudentWithMemorization[]) => {

        for (var i = 0; i < getDaysFromDates(); i++) {
          
              let date = new Date();
              if(selectedStartDate){
                  date = new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth(), selectedStartDate.getDate());
                  
                  if(i > 0)
                  {
                    date.setDate(date.getDate() + i);
                  }
              }

              const hasCompletedMemorization = date && 
                                               group.some(x=> x.memorization && 
                                                x.memorization.completedDate &&
                                                GetDateString(new Date(x.memorization.completedDate)) === GetDateString(date)) ? "yes" : "no"
              
              const cellStyle = {backgroundColor: hasCompletedMemorization === "yes" ? "green" : "yellow"};

              rows.push( <tr  key={uuidv4()}>
              <td>
                {group[0].student.fullName}
              </td>
              <td>
                {group[0].student.number}
              </td>
              <td style={cellStyle}>
                {
                  hasCompletedMemorization
                }
              </td>
              <td>
                {
                date && GetDateString(date)
                }
              </td>
              </tr>)

              studentMemorizationFlatStructure.push({
                  name:group[0].student.fullName, 
                  number: group[0].student.number,
                  participated: hasCompletedMemorization,
                  date: date && GetDateString(date)
              });
        }
      });

      setStudentMemorizationFlatStructure({studentMemorization:studentMemorizationFlatStructure});

      return rows;
  }
  
  return (
    <>
      {<p style={{marginLeft:"10px"}}><b>Student absence report</b></p>}
      <div style={{display:"flex", marginLeft:"5px", marginBottom:"10px"}}>
          <div style={{width: "130px"}}>
            <DatePicker
                key="1"
                title="Startdate"
                selected={selectedStartDate}
                onChange={handleStartDateChange}
                onSelect={handleStartDateChange}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
          </div>
          <div style={{width: "130px", marginLeft:"5px"}}>
            <DatePicker
                key="2"
                title="Enddate"
                selected={selectedEndDate}
                onChange={handleEndDateChange}
                onSelect={handleEndDateChange}
                dateFormat="dd/MM/yyyy"
                className="form-control"
              />
          </div>
        </div>
        <div style={{marginBottom: "20px"}}>
          {errorState.error.startDateError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.startDateError}</label>}
          {errorState.error.endDateError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.endDateError}</label>}
          {errorState.error.startDateBiggerThanEndDateError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.startDateBiggerThanEndDateError}</label>}
          </div>
          <div style={{marginLeft:"5px",  marginBottom:"20px"}}>
            <Button style={{backgroundColor:"#1D5F35", width:"155px"}} variant="secondary" onClick={handleSearchStudents}>
            {isLoading ?  (
                      <>
                      <FontAwesomeIcon icon={faSpinner} spin />
                      <span style={{ marginLeft: '0.5rem' }}>Searching...</span>
                    </>
                  ) : (
                    'Search'
                  )}
            </Button>
          </div>
        <div style={{marginLeft:"5px", marginBottom:"10px"}}>
           <Button style={{backgroundColor:"#1D5F35"}} variant="secondary" onClick={handleDownloadExcel}>
              Download as excel
            </Button>
        </div>
        {studentWithMemorization.length >= 0 &&  
        <div style={{overflowY: "auto", height:"400px", marginTop:"30px"}}>
        <Table striped bordered hover>
            <thead style={{ 
              position: "sticky", 
              top: 0, 
              background:"#135128", 
              color:"white", 
              fontFamily:"sans-serif",
              fontSize:"15px"}}>
              <tr>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Name</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Number</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Participated</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Date</th>
              </tr>
            </thead>
            <tbody style={{height:"10px !important", fontFamily:"sans-serif", fontSize:"13px"}}>
              {rowsState.rows}
            </tbody>
          </Table>
          </div>
         }    
    </>
  );
}

