
export const RegisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
        navigator.serviceWorker
            .getRegistrations()
            .then((registrations) => {
            for (const registration of registrations) {
                registration.unregister();
            }
            })
            .then(() => {
            navigator.serviceWorker.register('/service-worker.js').then((registration) => {
                registration.onupdatefound = () => {
                const newServiceWorker = registration.installing;
        
                if(newServiceWorker){
                    newServiceWorker.onstatechange = () => {
                        if (newServiceWorker.state === 'installed') {
                        if (navigator.serviceWorker.controller) {
                            // A new service worker version is installed
                            // Display a notification to inform the user
                            //console.log('A new version of the app is available.');
            
                            // You can customize the notification further if needed
                            // For example, show a toast notification or a modal dialog
                            // Here's a simple example using the Notification API:
                            if (Notification.permission === 'granted') {
                            new Notification('New Version Available', {
                                body: 'A new version of the app is available. Refresh to update.',
                            });
                            }
                        }
                        }
                    };
                }
                };
            });
            })
            .catch((error) => {
            console.error('Service Worker registration failed:', error);
            });
        });
    }
}
  