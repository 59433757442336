import React, { useEffect, useState } from 'react';
import { PaymentService } from '../../services/payment-service';
import { IPayment } from '../../models/payment';
import {IPaymentRequest} from '../../models/payment-request';
import { ReportPaymentTable } from './report-payment-table';

export function ReportPaymentContainer() {

  const [paymentState, setPaymentsState] = useState({
    payments: {} as IPayment[],
  });

  const [isLoading, setIsLoading] = useState(false);

  const paymentService = new PaymentService();

  const getPaymentsByDate = async (payment: IPaymentRequest) => {

    setIsLoading(true);

    await paymentService
      .getPaymentsByDate(payment)
      .then((data) => {
        if (data) {
          setPaymentsState({ payments: data });
        }
      });

      setIsLoading(false);
  };

  const handleSearchPayment = (payment: IPaymentRequest) => {
      getPaymentsByDate(payment);
  }

  return (
    <>
      {
        <ReportPaymentTable 
           payments={paymentState.payments}
           isLoading={isLoading}
           onSearchPayments= {handleSearchPayment}/>
      }
    </>
  );
}

