import { BaseApiService, IBaseApiService } from "./base-service";

const url = `api/Video/`;
const createToken = "CreateToken";

export interface IVideoService {
    createToken(channelName: string): Promise<string>;
}

export class VideoService implements IVideoService {
  private readonly baseService: IBaseApiService;
  constructor() {
    this.baseService = new BaseApiService();
  }
 
  public async createToken(channelName: string): Promise<string> {
    try {
      return await this.baseService.authGet<string>(
        `${url}${createToken}/${channelName}`
      );
    } catch (error: any) {
      return error;
    }
  }

}
