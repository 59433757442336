import React, { useEffect, useState } from 'react';
import { UserProfile } from './user-profile';
import { IStudentService, StudentService } from '../../../services/student-service';
import { AccountService, IAccountService } from '../../../services/account-service';
import { IUser } from '../../../user/user';
import { useNavigate } from 'react-router-dom';
import { getAuthDataFromLocalStorage } from '../../auth/model/auth-utils';

export function UserProfileContainer() {

  const [studentNumbersState, setStudenNumbers] = useState([] as string[]);
  const [customError, setCustomError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [emailIsUniqueState, setEmailIsUniqueState] = useState(false);

  const navigate = useNavigate();

  useEffect(()=>{
    getStudentNumbers();
  },[]);

  const studentService: IStudentService = new StudentService();
  const accountService: IAccountService = new AccountService();

  const getStudentNumbers = async () => {
    await studentService
      .getStudentNumbers()
      .then((data) => {
        if (data) {
            setStudenNumbers(data.map(s=> String(s)));
        }
      });
  };

  const isEmailUniq = async (email: any) => {
    return await accountService.isEmailUniq(email)
  };

  const createUser= async (user: IUser) => {
    setIsLoading(true);

    const emailUniq = await isEmailUniq({"email": user.email});

    if(emailUniq){
      setIsLoading(false);
      setEmailIsUniqueState(true);
    }
    else{
      const {error} =  await accountService.createUser(user)
      setIsLoading(false);
      setEmailIsUniqueState(false);
      if(error){
        setCustomError(error);
      }
      else{
        navigate("/editprofile");  
      }
    }
  };

  return (
    <>
        <UserProfile 
            studentNumbers={studentNumbersState} 
            createUser={createUser} 
            customError={customError}
            isLoading={isLoading}
            emailIsUnique = {emailIsUniqueState}/>
    </>
  );
}

