import { IPayment } from "../models/payment";
import { IPaymentRequest } from "../models/payment-request";
import { BaseApiService, IBaseApiService } from "./base-service";

const url = `api/Payment/`;
const getPayments = "GetPayments";
const addPayment = `AddPayment`;
const updatePayment = `UpdatePayment`;
const deletePayment = `DeletePayment`;
const getPaymentsByDate = `GetPaymentsByDate`;

export interface IPaymentService {
    updatePayment(payment: IPaymentRequest): Promise<any>;
    getPaymentsByDate(payment: IPaymentRequest): Promise<any>;
    addPayment(payment: IPaymentRequest): Promise<any>;
    getPayments(studentId: string): Promise<IPayment[]>;
    deletePayment(paymentId: string): Promise<boolean>;
}

export class PaymentService implements IPaymentService {
  private readonly baseService: IBaseApiService;
  constructor() {
    this.baseService = new BaseApiService();
  }
 
  public async getPayments(studentId: string): Promise<IPayment[]> {
    try {
      return await this.baseService.authGet<IPayment[]>(
        `${url}${getPayments}/${studentId}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async deletePayment(paymentId: string): Promise<boolean> {
    try {
      return await this.baseService.authGet<boolean>(
        `${url}${deletePayment}/${paymentId}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async addPayment(payment: IPaymentRequest): Promise<any> {
    
    try {
      return await this.baseService.authPost<IPaymentRequest,IPayment[]>(
        `${url}${addPayment}`,
        payment
      );
    } catch (error: any) {
      return error;
    }
  }

  public async updatePayment(payment: IPaymentRequest): Promise<any> {
    
    try {
      return await this.baseService.authPost<IPaymentRequest,IPayment[]>(
        `${url}${updatePayment}`,
        payment
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getPaymentsByDate(payment: IPaymentRequest): Promise<any> {
    
    try {
      return await this.baseService.authPost<IPaymentRequest,IPayment[]>(
        `${url}${getPaymentsByDate}`,
        payment
      );
    } catch (error: any) {
      return error;
    }
  }

}
