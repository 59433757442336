import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng';
import { IUserTrack } from './video-room';

export interface IVideoPLayer{
  user: IUserTrack;
  style: CSSProperties | undefined;
}

export function VideoPlayer(props: IVideoPLayer) {

  const {user, style} = props;

  const ref = useRef<any>();

  useEffect(()=>{
    user.videoTrack.play(ref.current);
  });

  return(
     <div>
       {/* Uid: {user.uid}*/}
        <div ref={ref} style={style}>

        </div>
       
     </div>
  );

}