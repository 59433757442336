import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { IMemorizationChapter } from '../../models/memorization-chapter';
import { Form } from 'react-bootstrap';
import { Chapters } from '../../consts/surah-const';
import { MemorizationChapterOptionStatus } from '../../enums/status-enums';

interface FormValues {
  [key: string]: string;
}

export interface IMemorizationChapterModal {
  memorizationChapters: IMemorizationChapter[];
  onUpdateMemorizationChapters: (memorizationChapters: IMemorizationChapter[])=> void;
  memorizationChapterOptionStatus: MemorizationChapterOptionStatus;
}

function MemorizationChapterOptionModal(props: IMemorizationChapterModal) {
 
   const {onUpdateMemorizationChapters, memorizationChapters, memorizationChapterOptionStatus} = props;
   const [selectedValues, setSelectedValues] = useState<FormValues>({});
   const [memorizationChaptersState, setMemorizationChapters] = useState(memorizationChapters);
   const [show, setShow] = useState(false);

   useEffect(()=>{
    if(memorizationChapters)
    {
      setMemorizationChapters(memorizationChapters);
    }
   },[memorizationChapters])

    const onCloseModal = () => {
        setShow(false);
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>, chapterDisplayName: any, type: number) => {
      const { name, value } = event.target;
      let selectedValuesLocal = {...selectedValues};

      if (selectedValuesLocal.hasOwnProperty(name) && selectedValuesLocal[name] === value) {
        delete selectedValuesLocal[name];

        const chapterIdLocal = value.split('_').shift();
        setSelectedValues(selectedValuesLocal);
        setMemorizationChapters(memorizationChaptersState.filter(mc=> mc.chapterId.toLocaleLowerCase() !== chapterIdLocal?.toLocaleLowerCase() ))
        return;
      }
      
      setSelectedValues({
          ...selectedValuesLocal,
          [name]: value
      });
  
      setMemorizationChapterFromOption(value as string, chapterDisplayName, type);
    };

    const setMemorizationChapterFromOption = (chapterId: any, chapterDisplayName: any, type: number) =>
    {
  
      const chapterIdLocal = chapterId.split('_').shift();
  
      let memorizationChaptersLocal = [...memorizationChaptersState];
  
      const memoChapter = Chapters.filter(x=> x.chapterId.toLocaleLowerCase() === chapterIdLocal.toLocaleLowerCase())[0];
      if(type === 1 || type === 2){
        memorizationChaptersLocal = memorizationChaptersLocal.filter(mc => mc.chapterId.toLocaleLowerCase() !== chapterIdLocal.toLocaleLowerCase());
        memorizationChaptersLocal.push({
          chapterId: chapterIdLocal, 
          chapterDisplayName,
          name: memoChapter.name,
          nameArabic: memoChapter.nameArabic, 
          memorizationType : type, 
          fromAyah: 1, 
          toAyah: Number(memoChapter.numberOfVerses) 
        } as IMemorizationChapter);
      }
  
      if(type === 3){
          memorizationChaptersLocal = memorizationChaptersLocal.filter(mc => mc.chapterId.toLocaleLowerCase() !== chapterIdLocal.toLocaleLowerCase());
          memorizationChaptersLocal.push({
            chapterId: chapterIdLocal, 
            chapterDisplayName, 
            name: memoChapter.name,
            nameArabic: memoChapter.nameArabic, 
            memorizationType : 1, 
            fromAyah: 1, 
            toAyah: Number(memoChapter.numberOfVerses) 
          } as IMemorizationChapter);
          memorizationChaptersLocal.push({
            chapterId: chapterIdLocal, 
            chapterDisplayName, 
            name: memoChapter.name,
            nameArabic: memoChapter.nameArabic, 
            memorizationType : 2, 
            fromAyah: 1, 
            toAyah: Number(Chapters.filter(x=> x.chapterId.toLocaleLowerCase() === chapterIdLocal.toLocaleLowerCase())[0].numberOfVerses) 
          } as IMemorizationChapter);
      }
  
      setMemorizationChapters(memorizationChaptersLocal);
  
    }

    const handleShowModal = ()=> {
        setShow(true);
        if(memorizationChapterOptionStatus === MemorizationChapterOptionStatus.Edit){
          setSelectedCheckboxValues();
        }
    }

    const handleUpdateMemorizationChapters = ()=> {
      setShow(false);
      onUpdateMemorizationChapters(memorizationChaptersState);
    }

    const setSelectedCheckboxValues = () => {
        let selectedValuesLocal = {...selectedValues};
    
        memorizationChapters.forEach((mc)=>{
          const mcLength = memorizationChapters.filter(c=> c.chapterId === mc.chapterId).length;

          if(mc.memorizationType === 1  && mcLength === 1){
            selectedValuesLocal[`${mc.chapterId.toLocaleUpperCase()}_optionGroup`] = `${mc.chapterId.toLocaleUpperCase()}_value1`;
          }
          if(mc.memorizationType === 2 && mcLength === 1){
            selectedValuesLocal[`${mc.chapterId.toLocaleUpperCase()}_optionGroup`] = `${mc.chapterId.toLocaleUpperCase()}_value2`;
          }
          if(mcLength === 2){
            selectedValuesLocal[`${mc.chapterId.toLocaleUpperCase()}_optionGroup`] = `${mc.chapterId.toLocaleUpperCase()}_value3`;
          }
        });
        
        setSelectedValues(selectedValuesLocal);
    };

    const chaptersLocal = [...Chapters].reverse();

    return (
      <>
        <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
          <input placeholder={memorizationChaptersState.length === 0 
                ? '0 er valgt' 
                : `${memorizationChaptersState.length} er valgt`} 
            id="memorizationChapterInput"
            readOnly
            autoFocus
            onClick={handleShowModal}
            style={{
              marginLeft:"1px", 
              marginRight:"5px", 
              width:"100%", 
              height:"40px", 
              borderRadius:"6px"}} 
            type="text">
          </input>
        </div>
       
        <Modal style={{borderRadius:"15px"}} show={show} onHide={onCloseModal} shouldUpdate={false}>
          <Modal.Body>
          <div style={{marginBottom: "20px"}}>
                {
                   <Form>
                    <div >
                      <div style={{ height:"100%"}}>
                      {chaptersLocal.map( ja => (
                        <Form.Group key={ja.chapterId}>
                              <div
                                 style={{borderTop:"solid 1px", padding:"10px", borderColor:"lightgray"}} 
                                 key={`${ja.chapterId}_checkboxDiv`}>
                                  <label>{`${ja.name} - ( ${ja.nameArabic}) `}</label>
                                  <br></br>
                                  <Form.Check 
                                        key={`${ja.chapterId}_checkboxHifz`}
                                        name={`${ja.chapterId}_optionGroup`}
                                        type="checkbox"
                                        id={`${ja.chapterId}_checkboxHifz`}
                                        label="Hifz"
                                        value={`${ja.chapterId}_value1`}
                                        checked={selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value1`}
                                        inline
                                        onChange={(e:any) => handleInputChange(e, `${ja.name} - ( ${ja.nameArabic}) `, 1)}
                                  />
                                  <Form.Check 
                                        key={`${ja.chapterId}_checkboxMurajara`}
                                        name={`${ja.chapterId}_optionGroup`}
                                        type="checkbox"
                                        id={`${ja.chapterId}_checkboxMurajara`}
                                        label="Murajara"
                                        value={`${ja.chapterId}_value2`}
                                        checked={selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value2`}
                                        inline
                                        onChange={(e:any) => handleInputChange(e, `${ja.name} - ( ${ja.nameArabic}) `, 2)}
                                  />
                                  <Form.Check 
                                        key={`${ja.chapterId}_checkboxBoth`}
                                        name={`${ja.chapterId}_optionGroup`}
                                        type="checkbox"
                                        id={`${ja.chapterId}_checkboxBoth`}
                                        label="Both"
                                        value={`${ja.chapterId}_value3`}
                                        checked={selectedValues[`${ja.chapterId}_optionGroup`] === `${ja.chapterId}_value3`}
                                        inline
                                        onChange={(e:any) => handleInputChange(e, `${ja.name} - ( ${ja.nameArabic}) `, 3)}
                                  />
                                  <br></br>
                              </div>
                        </Form.Group>
                        ))} 
                      </div>
                    </div>
                 </Form>
                }

              </div>
          </Modal.Body>
  
          <Modal.Footer style={{
            position:"sticky",
            bottom:"0",
            backgroundColor:"#fff",
            padding:"20px",
            
            }}>
            <Button style={{backgroundColor:"#1D5F35"}} variant="primary" onClick={onCloseModal}>
              Fortryd
            </Button>
            <Button style={{backgroundColor:"#1D5F35"}} variant="primary" onClick={handleUpdateMemorizationChapters}>
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default MemorizationChapterOptionModal;