import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { UserHasRoles } from '../../user/role';
import { useUserContext } from '../../user/user-context';

export interface IBookMemorizationSessionModal{
  onBookSession: (online: boolean)=> void;
  studentHasBookedMemorizationSession: boolean;
}

function BookMemorizationSessionModal(props: IBookMemorizationSessionModal) {

    const {onBookSession, studentHasBookedMemorizationSession} = props;
    const {userContext} = useUserContext();

    const [show, setShow] = useState(false);
    const [online, setOnline] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onClickBookSession = () => {
      props.onBookSession(online);
      setOnline(false);
      setShow(false);
    }

    const onChangeOnlineValue = () => {
      setOnline(!online);
    }
  
    return (
      <>
        {!studentHasBookedMemorizationSession && UserHasRoles(userContext,["Student"])  && 
        <Button style={{backgroundColor:"#1D5F35", marginLeft: "9px", marginBottom: "15px"}} variant="primary" onClick={handleShow}>
          Book hifz session
        </Button>}
  
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Book hifz session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
           <Form>
              <Form.Check 
                type="checkbox"
                id="custom-checkbox"
                label="Online session"
                onChange={onChangeOnlineValue}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onClickBookSession}>
              Book
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default BookMemorizationSessionModal;