import * as React from "react";

export function ForgotPassword() {

  return (
      <div >
        <form noValidate>
          <h2>Glemt password</h2>
          <label>
            Glemt dit password? Indtast din email adresse. Du vil modtage et
            link så du kan oprette et nyt password via din email.
          </label>
          <input
            required
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <button
            type="submit"
            color="primary"
          >
            Nulstil password
          </button>
        </form>
      </div>
  );
}