import React, { createContext, useContext } from "react";
import { IUser } from "./user";

export interface IUserContext {
  userContext: IUser;
}

const UserContext = createContext({} as IUserContext);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(`useUserContext must be used within a UserProvider`);
  }
  return context;
};

export const UserProvider = (props: React.PropsWithChildren<IUserContext>) => {
  const { children, ...userContext } = props;

  return (
    <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
  );
};
