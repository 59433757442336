import React, { useEffect, useState } from 'react';
import { IStudent } from '../../models/student';
import { UserHasRoles } from '../../user/role';
import { useUserContext } from '../../user/user-context';
import { SearchStudent } from '../search-student/search-student';
import { PaymentService } from '../../services/payment-service';
import { IPayment } from '../../models/payment';
import { PaymentTable } from './payment-table';
import {IPaymentRequest} from '../../models/payment-request';

export function RegisterPaymentContainer() {

  const {userContext} = useUserContext();

  const [studentState, setStudentState] = useState({
    student: {} as IStudent,
  });

  const [paymentState, setPaymentsState] = useState({
    payments: {} as IPayment[],
  });

  const paymentService = new PaymentService();

  const handleSelectStudent = async (student: IStudent) => {
      getPaymentsByStudent(student.studentId);
      setStudentState({student});
  }

  const handleClearSearchValue = () => {
    setStudentState({student: {} as IStudent})
  }

  const getPaymentsByStudent = async (studentId: string) => {
    await paymentService
      .getPayments(studentId)
      .then((data) => {
        if (data) {
          setPaymentsState({ payments: data });
        }
      });
  };

  const addPayment= async (payment: IPaymentRequest) => {
    await paymentService
      .addPayment(payment)
      .then((data) => {
        getPaymentsByStudent(studentState.student.studentId);
      });
  };

  const updatePayment= async (payment: IPaymentRequest) => {
    await paymentService
      .updatePayment(payment)
      .then((data) => {
        getPaymentsByStudent(studentState.student.studentId);
      });
  };

  const deletePayment= async (paymentId: string) => {
    await paymentService
      .deletePayment(paymentId)
      .then((data) => {
        getPaymentsByStudent(studentState.student.studentId);
      });
  };

  const handleAddPayment = (payment: IPaymentRequest) => {
      addPayment(payment);
  };

  const handleUpdatePayment = (payment: IPaymentRequest) => {
      updatePayment(payment);
      
  };

  const handleDeletePayment = (paymentId: string) => {
    deletePayment(paymentId);
    
};

  return (
    <>
     {<p style={{marginLeft:"10px"}}><b>Register payment</b></p>}
     {UserHasRoles(userContext,["Administrator"]) &&  
      <div>
        <SearchStudent onSelectStudent={handleSelectStudent} onClearSearchValue={handleClearSearchValue}/>
      </div>}
      {
        studentState.student.fullName &&
        <PaymentTable 
           payments={paymentState.payments}
           student={studentState.student}
           onAddPayment={handleAddPayment} 
           onUpdatePayment={handleUpdatePayment}
           onDeletePayment={handleDeletePayment}/>
      }
    </>
  );
}

