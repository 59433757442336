import * as React from "react";
import { Header } from "./components/header/header";
import { Outlet } from "react-router-dom";
import { useTouchScreenLongPressEffect } from "./components/touch-screen-long-press/touch-screen-long-press";
import { RefreshButton } from "./components/refresh-button/refresh-button";

export interface ILayoutProps {
  children?: React.ReactNode;
}

export function BasePageLayout() {

  const isLongPress = useTouchScreenLongPressEffect();
  
  return (
    <>
      {isLongPress && <div style={{
          position:"absolute", 
          zIndex:"9999999",  
          overflow:"visible",
          marginLeft:"45%", 
          marginTop: "20%", 
          backgroundColor: "white",
          borderRadius:"50%",
          width:"32px",
          height:"32px"}}>
          <RefreshButton showRefreshIcon={isLongPress} />
      </div>}
      <Header/>
      <Outlet/>
    </>
  );
}
