import React, { useEffect, useState } from 'react';
import { IStudent } from '../../models/student';
import { IMemorizationService, MemorizationService } from '../../services/memorization-service';
import { UserHasRoles } from '../../user/role';
import { useUserContext } from '../../user/user-context';
import { SearchStudent } from '../search-student/search-student';
import { AbsenceCalendar } from './absencecalendar/absence-calendar';

export function StudentAbsenceContainer() {

  const {userContext} = useUserContext();

  const [completedDatesState, setCompletedDatesState] = useState({
    completedDates: [] as Date[],
  });

  const [studentState, setStudentState] = useState({
    student: {} as IStudent,
  });

  useEffect(()=>{
    if(!UserHasRoles(userContext,["Systemadministrator","Teacher"])){
      handleSelectStudent({studentId: userContext.studentId } as IStudent);
    }
  },[]);

  const memorizationService: IMemorizationService = new MemorizationService();

  const getCompletedMemorizationDatesByStudentId = async (studentId: string) => {
    await memorizationService
      .getCompletedMemorizationDatesByStudentId(studentId)
      .then((data) => {
        if (data) {
          setCompletedDatesState({ completedDates: data });
        }
      });
  };

  const handleSelectStudent = async (student: IStudent) => {
      await getCompletedMemorizationDatesByStudentId(student.studentId);
      setStudentState({student});
  }

  const handleClearSearchValue = () => {
    setCompletedDatesState({ completedDates: [] });
    setStudentState({student: {} as IStudent})
  }

  return (
    <>
     {UserHasRoles(userContext,["Administrator","Teacher"]) &&  <div>
        <SearchStudent onSelectStudent={handleSelectStudent} onClearSearchValue={handleClearSearchValue}/>
      </div>}
      <br></br>
      {studentState.student.studentId && <AbsenceCalendar completedDates={completedDatesState.completedDates}/>}
    </>
  );
}

