import './terms.css';
export function Term() {


  return (
    <>
    <div style={{marginLeft:"10px", marginRight:"10px", overflowY: "auto", height:"100vh"}}>

        <div style={{textAlign:"center", marginTop:"10px"}}><b>Terms og use</b></div>

        <div style={{marginTop:"20px"}}>Last updated: 03-07-2023</div>

        <div style={{marginTop:"20px"}}>
            These Terms of Use <b>(Terms)</b> govern your use of our website located at https://hifz.dk, made available by Marqas – with CVR xxxxxxxx. (<b>Hifz</b>, <b>Company</b>, <b>we</b>, <b>us</b> and <b>our</b>), a private company established under the laws of Denmark having its registered office in Copenhagen, Denmark. The terms <b>you</b> and <b>your</b> refer to the user of the Platform.
        </div>

        <div style={{marginTop:"20px"}}>
           Our Services (as we have described below in detail) and these Terms are compliant with the Danish Law. When you create an account on our Platform or use our Platform or any of our Services, you accept and agree to these Terms. However, please note that we do not represent that we are compliant with laws of any country apart from Denmark. If you wish to use our Services, please ensure that you are permitted to do so, in your jurisdiction. By accessing this website and using our any affiliate platform. we assume you accept these terms and conditions. Do not continue to use Hifz if you do not agree to all of the terms and conditions stated on this page
        </div>

        <div style={{marginTop:"20px"}}>
             You and we are required to follow certain rules while you use our Platform. We have listed these rules in these Terms. Please read these Terms mentioned here carefully. Do remember that by using our Platform, you agree to these Terms. Also, if you are using these services outside Denmark, please comply with your local laws. 
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>CHANGES TO TERMS AND SERVICES</b>
        </div>

        <div style={{marginTop:"20px"}}>
          Our Platform is dynamic and may change rapidly. As such, we may change the services we provide at our discretion. We may temporarily, or permanently, stop providing Services or any features to you generally.
        </div>

        <div style={{marginTop:"20px"}}>
            We may remove or add functionalities to our Platform and Services without any notice. However, if we make a change where your consent is required, we will make sure to ask for it. Please be sure to keep visiting this page from time to time to stay updated on our latest changes and developments.
        </div>

        <div style={{marginTop:"20px"}}>
            Visit this page to see any changes that we may make and services that we may add or modify, from time to time.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>WHO MAY USE OUR SERVICES</b>
        </div>

        <div style={{marginTop:"20px"}}>
            Our Platform helps you (student) to have a simple and intuitive overview of your weekly hifz and murajara sessions. It helps the teacher to create Hifz/ murajara homework. It helps the Administrator to create and edit students enrolled to the hifz program. Furthermore the Administrator is able to register payments and create reports that show the students participation in the hifz program and payments.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>PRIVACY POLICY</b>
        </div>

        <div style={{marginTop:"20px"}}>
            To effectively provide and introduce any new Services to you, we collect certain information such as your phone number, your gender and your name from you. We may further request and store additional information. Such information is stored securely on azure web services or “Azure” cloud servers, thereby also subject to the terms of the Azure privacy policy. The Hifz Privacy Policy explains how we collect, use, share and store the information collected. The Hifz Privacy Policy also details your rights under law and how you may control the data you provide us (see below).
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>YOUR COMMITMENTS</b>
        </div>

        <div style={{marginTop:"20px"}}>
            Providing a safe Service for a broad community requires that we all do our part. In return for our commitment to provide our Services, we require you to make some commitments to us. Your commitments to us are:
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>No Impersonation or False Information to be Provided</u>
        </div>

        <div style={{marginTop:"20px"}}>
            While you do not have to use your actual name on our Platform, the administrator will input your correct name, email, phone number and gender to use our Services. You will not falsely represent yourself as another person or representative of another person to use our Services.
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Device Security</u>
        </div>

        <div style={{marginTop:"20px"}}>
            We have implemented measures to ensure that our Platform is secure. However, there is no guarantee that our Platform is immune to hacking and virus attacks. You will ensure that you have requisite anti-malware and anti-virus software on your mobile devise and computer to ensure its safety. You will not allow any person to use your phone number, and not allow multiple accounts to be linked to your phone number. You will be responsible for all content posted by any account linked to your phone number.
        </div>

        <div style={{marginTop:"20px"}}>
            While we do everything, we can to secure your use of our Services, keep in mind that we cannot contemplate all forms of attack on our Platform. You should, as a matter of practice, ensure that your mobile device and computer are not used wrongly or tampered with in any way.
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Content Removal and Termination</u>
        </div>

        <div style={{marginTop:"20px"}}>
            Your usage of our Platform is governed by the Hifz Content and Community Guidelines. If any of our users report your content to be against the Hifz Content Community Guidelines, we may remove such content from our Platform. In the event that multiple reports are made regarding violation of the Hifz Content and Community Guidelines, we may be compelled to terminate your account – regardless of whether you are a student or teacher - with us and block you from registering with us. Any payments is not refundable. If you wish to appeal any such removal, you may write to us at info@hifz.com
        </div>

        <div style={{marginTop:"20px"}}>
            We may remove any content that is shared on our Platform if such content is prohibited under the Hifz Content and Community Guidelines.
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Platform Not to be Used For Anything Unlawful or Illegal</u>
        </div>

        <div style={{marginTop:"20px"}}>
            Our Platform is designed to accommodate a multiplicity of languages and cultures, as well as a diverse range of content and functionalities. You must therefore, correctly identify the nature of the content shared by you and uphold a decent and appropriate language.
        </div>

        <div style={{marginTop:"20px"}}>
            You shall not, however, use our Platform to share any content which is obscene, pornographic, harmful for minors, discriminatory, spreading hate speech, inciting any form of violence or hatred against any persons, or of seditious in nature, or violates any laws of Denmark, or is barred from being shared by any laws of Denmark. We reserve the right to remove such content. Please read the QuranLoad Content and Community Guidelines for further details.
        </div>

        <div style={{marginTop:"20px"}}>
            In addition to the above, please note that we may share your information with appropriate law enforcement authorities if we have good-faith belief that it is reasonably necessary to share your personal data or information in order to comply with any legal obligation or any government request; or to protect the rights or prevent any harm to our property or safety, our customers, or public; or to detect, prevent or otherwise address public safety, fraud, security or technical issues.You understand however, that we cannot be held responsible for any actions done by or to you by a third party or user by way of using our Platform.
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Content Rights and Liabilities</u>
        </div>

        <div style={{marginTop:"20px"}}>
            We strongly believe in the freedom of expression and allow you to share content on our Platform. We do not have any ownership over any of the content shared by you and the rights in the content remain only with you. You will not use our Platform to violate or infringe upon our or any third-party’s intellectual property rights. Such content is against Hifz Content and Community Guidelines and may be removed from the Platform. Further, if you use any content developed by us, then we shall continue to own the intellectual property rights in such content.
        </div>

        <div style={{marginTop:"20px"}}>
            By sharing content using our Services, you grant us a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to host, use, distribute, run, copy, publicly perform or display, translate, and create derivative works of your content (consistent with your privacy and application settings). You may ask the administrator to delete your content and/or account at any point. However, your content may continue to appear on the Platform if it has been shared with others. To learn more about how we use information, and how to control or delete your content, please read the Hifz Privacy Policy.
        </div>

        <div style={{marginTop:"20px"}}>
            <u>You remain solely responsible for the content shared on our Platform. We do not endorse and are not responsible for any content shared or posted on or through our Platform, and for the consequence of such sharing or posting. The presence of our logo or any trademark on any content shared by you does not mean that we have endorsed or sponsored your content. Further, we will not be liable for or responsible for the consequences of any transactions made or entered into by you with other users of the Platform. </u>
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Intermediary Status and No Liability</u>
        </div>

        <div style={{marginTop:"20px"}}>
            We do not control what people do or say and are not responsible for their (or your) actions (whether online or offline). We are not responsible for services and features offered by others, even if you access them through our Services. Our responsibility for anything that happens on our Platform is strictly governed by the laws of Denmark and is limited to that extent. You agree that we will not be responsible for any loss of profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms, even if we know they are possible. This includes when we delete your content, information, or account. We are an intermediary under Danish law. We do not control what people post on our Platform but we expect everyone to comply with the Hifz Content and Community Guidelines. 
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>You Will Not Attempt to Disrupt or Jeopardize QuranLoad</u>
        </div>

        <div style={{marginTop:"20px"}}>
            We have developed a memorization platform. Therefore, you agree to not interfere with, or use non-public areas of our Platform, Services, and our technical delivery system. You will not introduce any trojans, viruses, any other malicious software, any bots or scrape our Platform for any user information. Additionally, you will not probe, scan, or test the vulnerability of any system, security or authentication measures implemented by us. If you tamper or attempt to tamper with our technological design and architecture, we may terminate your user profile. We may further report such actions to the appropriate law enforcement authorities and proceed against you with legal actions.
        </div>

        <div style={{marginTop:"20px"}}>
            You will not hack into or introduce malicious software of any kind onto our Platform. If you commit such actions, we may remove you from the platform and even have to take legal action.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>PERMISSIONS YOU GIVE TO US</b>
        </div>

        <div style={{marginTop:"20px"}}>
            You accept these Terms and give us certain permissions so that we can serve you better. Permissions you have granted us are:
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Permission to Share Your Profile Information with Third Parties</u>
        </div>

        <div style={{marginTop:"20px"}}>
             While our Platform is an accessible and usable platform, we need to generate revenue so that we can keep offering organizations our Services at a low cost level. Pursuant to this, we may share your user name/name, e-mail and gender, and any data that we may collect relating to your usage habits and patterns on our Platform to show you any sponsored content or advertisements. We will not, however, be liable to pay you any revenue share if you purchase any products advertised to you. We do not endorse any products or vouch for the authenticity of the products. Merely advertising of products by the users on our Platform does not amount to an endorsement by us.
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Automatic Downloads and Updates</u>
        </div>

        <div style={{marginTop:"20px"}}>
            We are constantly updating our Platform and Services offered. To use our Platform, you may need to download the Hifz web app to your mobile device and refresh it from time to time.
        </div>

        <div style={{marginTop:"20px"}}>
            We may use cookies, browser local storage and similar files or technologies to collect and store information with respect to your use of the Services and third-party websites. Please see the Hifz Cookie Policy for more information regarding the use of cookies and other technologies described in this section, including regarding your choices relating to such technologies.
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Data retention</u>
        </div>

        <div style={{marginTop:"20px"}}>
            We shall have the right to retain certain information regarding your usage of the Platform. Please view the Hifz Privacy Policy for further information relating to the collection, storage and use of your information by us.
        </div>

        <div style={{marginTop:"20px"}}>
            You grant us the right to store and retain information relating to you and provided by you. Please see the Privacy Policy for further information.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>OUR AGREEMENT AND WHAT HAPPENS IF WE DISAGREE</b>
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Who Has Rights Under These Terms</u>
        </div>

        <div style={{marginTop:"20px"}}>
            The rights and obligations under these terms are granted only to you and shall not be assigned to any third party without our consent. However, we are permitted to assign our rights and obligations under these Terms to others. This can happen when, for example, we enter into a merger with another company and create a new company.
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>How We Will Handle Disputes</u>
        </div>

        <div style={{marginTop:"20px"}}>
            In all cases, you agree that disputes will be subject to the laws of Denmark and the courts of Denmark, as first instance The Copenhagen City Court or the Eastern High Cort shall have exclusive jurisdiction over all such disputes.
        </div>

        <div style={{marginTop:"20px"}}>
            1. <u>Grievance Officer</u>
        </div>

        <div style={{marginTop:"20px"}}>
            We have a Grievance Officer to address your concerns regarding data safety, privacy, and Platform usage concerns. We will resolve the issues raised by you within 60 (sixty) days from receiving them. You may contact the Grievance Officer at any of the following:
        </div>

        <div style={{marginTop:"20px"}}>
            Email: info@hifz.com
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>Fair USE;</b>
        </div>

        <div style={{marginTop:"20px"}}>
            As a platform we encourage users to utilize it fairly and discourage any kind of misue, which may lead to suspension:
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>INDEMNIFICATION:</b>
        </div>

        <div style={{marginTop:"20px"}}>
            You agree to indemnify, defend and hold us harmless, and our subsidiaries, partners, affiliates, sub-affiliates and agents and their respective officers, directors, employees, successors and assigns from and against any claim, proceeding, loss, damage, liability, cost, demand or expense (including but not limited to attorney's fees) of any kind arising out of: (i) your access to or use of the Platform and Services; (ii) any breach by you of your obligations under this Agreement; (iii) your violation of the rights of any third party, including any infringement of intellectual property, or of any privacy or consumer protection right; (iv) any violation of law or contractual obligation and any claims, demands, notices pursuant to such violation; (v) your negligence or willful misconduct. This obligation will survive termination of our Terms.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>UNSOLICITED MATERIAL</b>
        </div>

        <div style={{marginTop:"20px"}}>
            We always appreciate feedback or other suggestions. We may use the same without any restrictions or obligation to compensate you for them and are under no obligation to keep them confidential.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>GENERAL</b>
        </div>

        <div style={{marginTop:"20px"}}>
            <p>1. If any aspect of these Terms is unenforceable, the rest will remain in effect.</p>
            <p>2. Any amendment or waiver to our Terms must be in writing and signed by us.</p>
            <p>3. If we fail to enforce any aspect of these Terms, including reporting any illegal or impermissible actions to appropriate law enforcement authorities or blocking or suspending your profile, such failure to enforce our rights will not be a waiver by us.</p>
            <p>4. We reserve all rights not expressly granted to you.</p>
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>PRIVACY POLICY</b>
        </div>

        <div style={{marginTop:"20px"}}>
            We (“Hifz”) recognise that your privacy is very important and take it seriously. This Privacy Policy (“Privacy Policy”) sets out how we collect, use and disclose your data when you use our website https://hifz.com/ (“Website”), our platform hifz.com. The Web app is collectively referred to as the “Platform”. References to “we”, “our” or “us” or the “Company” shall mean the Platform and/or Hifz. Any references to “you”, “your” or “user” shall mean any person or entity using our Platform. We will not use or share your information with anyone except as described in this Privacy Policy.
        </div>

        <div style={{marginTop:"20px"}}>
            By using this Platform, you agree to the terms and conditions of this Privacy Policy. You also consent to our use and disclosure of your Personal Information (as defined below) in the manner described in this Privacy Policy. The capitalized words used in this Privacy Policy but not defined herein, shall have the meaning given to such words in the Terms. If you do not agree with the terms and conditions of this privacy policy, please do not use this platform.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>THE INFORMATION WE COLLECT AND HOW WE USE IT</b>
        </div>

        <div style={{marginTop:"20px"}}>
            The following table lists the information we collect from you and how we use it:
        </div>

        <div style={{marginTop:"20px"}}>
            Log-in Data. User ID, name, mobile phone number, password, email and gender.
        </div>

        <div style={{marginTop:"20px"}}>
            <ul style={{backgroundColor:"white"}}>
                <li >To set up and facilitate log-in to a user account on Platform;</li>
                <li>To notify you about changes to the Platform, including this Privacy Policy;</li>
                <li>To facilitate communication, including provision of user support;</li>
                <li>To enforce our terms, conditions, and policies and any of our rights, or the rights of our affiliate companies, or other users of the Platform;</li>
                <li>To develop new services and improve the existing services and the Platform and integrate user feedback and requests;</li>
                <li>To provide language and location based personalization ;</li>
                <li>To administer the Platform and for internal operations, including troubleshooting, data analysis, testing, research, security, fraud-detection, account management, and survey purposes;</li>
                <li>To better understand how you use and access the Platform and to improve the user experience on the Platform;</li>
                <li>For customizing your “Feeds” </li>
                <li>To pseudonymous and aggregate your information, including Personal Information, for conducting user demographic analysis on items such as region, phone model, operating system platform, system language, and the Platform version in order to better understand how our users are using Platform;</li>
                <li>To pseudonymous and aggregate your information, including Personal Information, for collection of web and account traffic statistics of what content and services are used when users access third-party services on the Platform;</li>
                <li>To assess effectiveness of and improve advertising and other marketing and promotional activities.</li>
            </ul>
        </div>

        <div style={{marginTop:"20px"}}>
            Log Data. "Log Data" is information that we automatically collect when you use the Platform, whether through the use of cookies or browser local storage.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>DISCLOSURE OF YOUR INFORMATION</b>
        </div>

        <div style={{marginTop:"20px"}}>
            We disclose your information in the following manner:
        </div>

        <div style={{marginTop:"20px"}}>
            <u>Content Visible to Others</u>
        </div>

        <div style={{marginTop:"20px"}}>
            Public content i.e. any content you post on your user profile or on another user’s profile, such as a post comment, is accessible to everyone, including search engines. Any information you voluntarily disclose for posting to the Platform, including your profile page information, is accessible to anyone. When you submit, post or share the content that you choose to make public, on the Platform, it may be re-shared by others. You should consider who you choose to share it with, because people who can see your activity on our Platform can choose to share it with others on and off our Platform, including people outside the audience you shared it with. For example, when you share a post or send a message to specific users of our Platform or accounts, they can download or re-share that content with other users across or off our Platform. Also, when you comment on someone else's post or like their content, your comment or like is visible to anyone who can see the other person's content. 
        </div>

        <div style={{marginTop:"20px"}}>
             Users can also use our Platform to create and share content about you with the audience that they choose, such as posting a photo of you, or tagging you in any of their posts. We reserve the right to share all public content on any social media site or any other online or offline platform. We will never rent or sell your Personal Information to third parties, except on an anonymized basis, unless expressly provided in these Terms.
        </div>

        <div style={{marginTop:"20px"}}>
            <u>Sharing with our Group of Companies</u>
        </div>

        <div style={{marginTop:"20px"}}>
            We may share your Personal Information with any member of our Group. The term “Group” shall mean any entity that is controlled by us, or any entity that is in control of us, or any entity that is under common control with us, whether directly or indirectly.
        </div>

        <div style={{marginTop:"20px"}}>
            What you Share with Others
        </div>

        <div style={{marginTop:"20px"}}>
            When you share content and communicate using our Platform, you choose the audience for what you share. For example, when you post any content from our Platform on Facebook, you select the audience for the post, such as a friend, a group of friends or all of your friends. Similarly, when you use Whats App or any other application on your mobile device to share the content on our Platform, you choose who you share the content with. We do not control and shall not be liable for the manner, in which such persons (with whom you choose to share content via any sharing options, such as Whats App or Facebook, available on the Platform) use the information that you share with them.
        </div>

        <div style={{marginTop:"20px"}}>
            <u>Sharing with Third Parties</u>
        </div>

        <div style={{marginTop:"20px"}}>
            We may share your information (including Personal Information) with select third parties including:
        </div>

        <div style={{marginTop:"20px"}}>
            <li>Business partners, suppliers and sub-contractors (“Affiliates”). Affiliates may use this information to help provide, understand, and improve the Service and Affiliates’ own services for the performance of any contract we enter into with you.</li>
            <li>Advertisers and advertising networks that require the data to select and serve relevant adverts to you and others. We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information about our users (for example, we may inform them that any given number of women in a specified age group clicked on their advertisement on any given day). We may also use such aggregate information to help advertisers reach the kind of audience they want to target.</li>
            <li>Government bodies or law enforcement agencies, if we have good-faith belief that it is reasonably necessary to share your personal data or information in order to comply with any legal obligation or any government request; or to protect the rights or prevent any harm to the property, or safety of the Company, our customers, or public; or to detect, prevent or otherwise address public safety, fraud, security or technical issues.</li>
        </div>

        <div style={{marginTop:"20px"}}>
            We may also disclose your information (including Personal Information) to select third parties in the following circumstances: 
        </div>

        <div style={{marginTop:"20px"}}>
            <li>If the Company or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets. If we are involved in a merger, acquisition, bankruptcy, reorganization or sale of assets such that your information would be transferred or become subject to a different Privacy Policy, we will notify you in advance so you can opt out of any such new policy by deleting your account before transfer.</li>
            <li>In order to enforce or apply our Terms [https://www.hifzcom/terms] and/or any other agreements.</li>
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>SECURITY PRACTICES</b>
        </div>

        <div style={{marginTop:"20px"}}>
            We have in place appropriate technical and security measures to secure the information collected by us. Where we have given you (or where you have chosen) a username and password which enables you to access the Platform, you are responsible for keeping these details confidential. We ask you not to share your password with anyone.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>WHERE WE STORE YOUR PERSONAL INFORMATION</b>
        </div>

        <div style={{marginTop:"20px"}}>
            We store your data with Azure cloud platform. 
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>CHANGES TO THIS POLICY</b>
        </div>

        <div style={{marginTop:"20px"}}>
            The Company may periodically update this Privacy Policy. Whenever we make any changes to this Privacy Policy that are important for you to know about, we will post the updated Privacy Policy at this link. It is your responsibility to keep checking this page from time to time in order to be aware of any changes made to this Privacy Policy.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>DISCLAIMER</b>
        </div>

        <div style={{marginTop:"20px"}}>
            Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your Personal Information, we cannot guarantee the security of your data transmitted to the Platform; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorized access.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>YOUR RIGHTS</b>
        </div>

        <div style={{marginTop:"20px"}}>
             You are free to ask the administrator to remove or delete content from your user account/profile and your account/ profile at any point in time. However, the history of your activities and account on our Platform remains available to us.
        </div>

        <div style={{marginTop:"20px"}}>
            You may ask the administrator to correct, amend, add or delete Personal Information from your account at any time by logging in and visiting your profile page. As noted above, you may opt out from unwanted e-mail communications from us by following the instructions in the message. However, until your account is deleted you will continue to receive all system e-mails.
        </div>

        <div style={{marginTop:"20px"}}>
            You have a right to ask us to review, correct and amend the information we collect about you at any point in time. You may also ask us not to process your personal data for marketing purposes. You can exercise any of these rights at any time by contacting us at [ info@hifz.com]. However, we require a reasonable period of 60 (sixty) days to comply with any of your requests.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>DATA RETENTION</b>
        </div>

        <div style={{marginTop:"20px"}}>
            We do not retain your Sensitive Personal Information (defined below in this paragraph) for longer than is required for the purposes for which the information may be lawfully used. For any other content, we will entertain your request for deletion, however, there is a strong possibility that copies of any public content will be retained indefinitely in our systems, including in cached and archived pages of the Platform, or if other users have copied or saved that information. In addition, due to the nature of the internet, copies of your content, including content that you have removed or deleted from your account, may also exist elsewhere on the Internet and be retained indefinitely. “Sensitive Personal Information” shall mean passwords and any other information that is classified as sensitive.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>THIRD PARTY LINKS</b>
        </div>

        <div style={{marginTop:"20px"}}>
            The Platform may, from time to time, contain links to and from the websites of our partner networks, advertisers, Affiliates and/or any other websites or mobile applications. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites or mobile applications.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>THIRD-PARTY EMBEDS</b>
        </div>

        <div style={{marginTop:"20px"}}>
             What Are Third Party Embeds?
        </div>

        <div style={{marginTop:"20px"}}>
            Some of the content that you see displayed on the Platform is not hosted by the Platform. These “embeds” are hosted by a third-party and embedded in the Platform. For example: YouTube, Vimeo, movi.io, Facebook or Google videos, Imgur or Giphy gifs, SoundCloud audio files, Twitter tweets, or Scribd documents that appear within a post on the Platform. These files send data to the hosted site just as if you were visiting that site directly (for example, when you load a Platform post page with a YouTube video embedded in it, YouTube receives data about your activity).
        </div>

        <div style={{marginTop:"20px"}}>
            <u>Privacy Concerns With Third Party Embeds</u>
        </div>

        <div style={{marginTop:"20px"}}>
            The Platform does not control what data third parties collect or what they will do with it. So, third-party embeds on the Platform are not covered by this Privacy Policy. They are covered by the privacy policy of the third-party service.
        </div>

        <div style={{marginTop:"20px"}}>
            <u>Sharing Personal Information With Third Party Embeds</u>
        </div>

        <div style={{marginTop:"20px"}}>
            Some embeds may ask you for Personal Information, such as your email address, payment information, through a form. We do our best to keep bad actors off of the Platform. However, if you choose to submit your information to a third party this way, we don’t know what they may do with it. As explained above, their actions are not covered by this Privacy Policy. So, please be careful when you see embedded forms on the Platform asking for your email address or any other Personal Information. Make sure you understand who you are submitting your information to and what they say they plan to do with it.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>COMMUNICATIONS FROM US</b>
        </div>

        <div style={{marginTop:"20px"}}>
             We may from time to time send you service-related announcements when we consider it necessary to do so (such as when we temporarily suspend the Platform for maintenance, or security, privacy, or administrative-related communications). We send these to you via any marketing channel. You may not opt-out of these service-related announcements, which might be of promotional in nature and/or to protect your account and keep you informed of important changes to the Platform.
        </div>

        <div style={{marginTop:"20px", fontSize:"17px"}}>
            <b>GRIEVANCE</b>
        </div>

        <div style={{marginTop:"20px"}}>
            Hifz address your concerns regarding data safety, privacy, and the Platform usage concerns. We will resolve the issues raised by you within 60 (sixty) days from receiving them. You may contact the email for such cases:
        </div>

        <div style={{marginTop:"20px"}}>
            Email: info@hifz.com
        </div>

     </div>
    </>
  );
}

