import { getAuthDataFromLocalStorage } from "../components/auth/model/auth-utils";
import { Configuration, IConfiguration } from "../configuration";

export interface IBaseApiService {
  get<TResponse>(url: string): Promise<TResponse>;
  post<TData, TResponse>(url: string, data: TData): Promise<TResponse>;
  authGet<TResponse>(url: string): Promise<TResponse>;
  authPost<TData, TResponse>(url: string, data: TData): Promise<TResponse>;
  delete<TData, TResponse>(url: string, data: TData): Promise<TResponse>;
}

export class BaseApiService implements IBaseApiService {
  private readonly urlBase: string;
  private readonly configuration: IConfiguration = new Configuration();

  constructor() {
    this.urlBase = this.configuration.baseAddress;
  }

  public get<TResponse>(url: string): Promise<TResponse> {
    return fetch(this.urlBase + url).then(
      (response) => response.json() as Promise<TResponse>,
      (error) => error
    );
  }

  public authGet<TResponse>(url: string): Promise<TResponse> {
    return fetch(this.urlBase + url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthDataFromLocalStorage().accessToken as string}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        'Cache-Control': 'no-cache'
      }
    }).then(
      (response) => response.json() as Promise<TResponse>,
      (error) => error
    );
  }

  public post<TData, TResponse>(url: string, data: TData): Promise<TResponse> {
    return fetch(this.urlBase + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify(data),
    }).then(
      (response: any) => {
        if (response.ok) {
          return response.json() as Promise<TResponse>;
        } else {
          return response;
        }
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  public authPost<TData, TResponse>(
    url: string,
    data: TData
  ): Promise<TResponse> {
    return fetch(this.urlBase + url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getAuthDataFromLocalStorage().accessToken as string}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify(data),
    }).then(
      (response: any) => {
        if (response.ok) {
          return response.json() as Promise<TResponse>;
        } else {
          return response;
        }
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  public delete<TData, TResponse>(
    url: string,
    data: TData
  ): Promise<TResponse> {
    return fetch(this.urlBase + url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Cache-Control': 'no-cache'
      },
      body: JSON.stringify(data),
    }).then(
      (response: any) => {
        if (response.ok) {
          return response.json() as Promise<TResponse>;
        } else {
          return response;
        }
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
