import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { IPayment } from "../../models/payment";
import { GetCurrentDate,GetDateString, GetMonthNameFromDateString, GetYearFromDateString } from "../../helpers/date-helper";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button} from "react-bootstrap";
import { IStudent } from "../../models/student";
import { IPaymentRequest } from "../../models/payment-request";
import { UpdatePaymentModal } from "./update-payment-modal";


interface IRegisterPaymentErrors{
  paymentDateError: string;
  monthError: string;
}
export interface IPaymentTable{
  payments: IPayment[];
  student: IStudent;
  onAddPayment: (payment: IPaymentRequest)=> void;
  onUpdatePayment: (payment: IPaymentRequest)=> void;
  onDeletePayment: (paymentId: string)=> void;
}

export function PaymentTable(props: IPaymentTable){

  const {payments, student, onAddPayment, onUpdatePayment, onDeletePayment} = props;

  const [selectedPaymentDate, setSelectedPaymentDate] = useState<Date>(GetCurrentDate());

  const [openModal, setOpenModal] = useState(false);

  const [paymentRow, setPaymentRow] = useState({
    payment: {} as IPayment
  });

  const [errorState, setErrorState] = useState({
    error: { } as IRegisterPaymentErrors
   });

  const rowClick = (paymentId: string) => {
     setOpenModal(true);
     setPaymentRow({payment: payments.filter(m=> m.paymentId === paymentId)[0]});
  }

  const handleOnHideModal = () => {
    setOpenModal(false);
    setPaymentRow({payment:{} as IPayment});
  }

  const SortDateDescending = (a:IPayment,b: IPayment) => {  
    var dateA = new Date(a.date).getTime();
    var dateB = new Date(b.date).getTime();
    return dateA < dateB ? 1 : -1;  
  }; 

  const handlePaymentDateChange = (date: Date) => {
    setSelectedPaymentDate(date);
  };

  const handleAddPayment = () => {

     if(hasError()) return;
      
      onAddPayment({
        studentId: student.studentId,
        date:  GetDateString(selectedPaymentDate)
      } as IPaymentRequest);
  };

  const handleUpdatePayment = (payment: IPaymentRequest) => {
    onUpdatePayment(payment);
    handleOnHideModal();
  };

  const handleDeletePayment = (paymentId: string) => {
    onDeletePayment(paymentId);
    handleOnHideModal();
  };


const hasError = ()=> {

  const errors = {error: {
    ...errorState.error, 
     paymentDateError: !selectedPaymentDate ? "Paymentdate is required" : ""
   }};

  setErrorState(errors);

  if(errors.error.paymentDateError || errors.error.monthError){
    return true;
  }

  return false;
}

  return (
    <>
       {
          <UpdatePaymentModal
              payment={paymentRow.payment}
              onHideModal={handleOnHideModal}
              onUpdatePayment={handleUpdatePayment}
              onDeletePayment={handleDeletePayment}
              show={openModal} />
        }
        {
          <div style={{marginTop:"15px"}}>
            <div style={{display:"flex"}}>
                <label style={{marginTop: "5px", marginLeft:"12px"}}>Payment day</label>
                <div style={{width: "130px", marginLeft:"10px", marginBottom: "5px"}}>
                  <DatePicker
                      key="1"
                      title="Startdate"
                      selected={selectedPaymentDate}
                      onChange={handlePaymentDateChange}
                      onSelect={handlePaymentDateChange}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                    />
                </div>
            </div>
            {errorState.error.paymentDateError && <label style={{color:"red", marginLeft:"10px", marginBottom:"10px"}}>{errorState.error.paymentDateError}</label>}
              <div style={{marginLeft:"10px", marginTop:"15px"}}>
              <Button style={{backgroundColor:"#1D5F35"}} variant="secondary" onClick={handleAddPayment}>
                Add payment
              </Button>
            </div>
        </div>
        }
        {payments.length >= 0 &&  
        <div style={{overflowY: "auto", height:"400px", marginTop:"30px"}}>
        <Table striped bordered hover>
            <thead style={{ 
              position: "sticky", 
              top: 0, 
              background:"#135128", 
              color:"white", 
              fontFamily:"sans-serif",
              fontSize:"15px"}}>
              <tr>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Name</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Number</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Year</th>
                <th style={{ background:"#135128", color:"white", fontSize:"15px"}}>Month</th>
              </tr>
            </thead>
            <tbody style={{height:"10px !important", fontFamily:"sans-serif", fontSize:"13px"}}>
            {payments.sort(SortDateDescending).map((payment :IPayment, index) => (
                      <tr  onClick={()=> rowClick(payment.paymentId)} key={index}>
                          <td>
                            {payment.student.fullName}
                          </td>
                          <td>
                            {payment.student.number}
                          </td>
                          <td>
                            {payment.date && GetYearFromDateString(payment.date.toString())}
                          </td>
                          <td>
                            {payment.date && GetMonthNameFromDateString(payment.date.toString())}
                          </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
          </div>
         }    
    </>
  );
}

