import React, { useEffect, useState } from 'react';
import { useUserContext } from '../../../user/user-context';
import { IStudentService, StudentService } from '../../../services/student-service';
import { IUser } from '../../../user/user';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '../user-profile/user-profile';
import { UserHasRoles } from '../../../user/role';
import { SearchUser } from '../../search-user/search-user';
import { AccountService, IAccountService } from '../../../services/account-service';
import { getAuthDataFromLocalStorage } from '../../auth/model/auth-utils';

export function EditUserProfileContainer() {

  const {userContext} = useUserContext();
  const [studentNumbersState, setStudenNumbers] = useState([] as string[]);
  const [userState, setUserState] = useState({} as IUser);
  const [customError, setCustomError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedInUserProfile, setIsLoggedInUserProfile] = useState(false);

  const navigate = useNavigate();

  useEffect(()=>{
    getStudentNumbers();

    if(!UserHasRoles(userContext,["Administrator"])){

      const getUserLocal = async () => {
        try {
          const userLocal = await getUser();
          setUserState(userLocal);
          setIsLoggedInUserProfile(true);
        } catch (error) {
        }
      };
      getUserLocal();
    }
  },[]);

  const studentService: IStudentService = new StudentService();
  const accountService: IAccountService = new AccountService();

  const getStudentNumbers = async () => {
    await studentService
      .getStudentNumbers()
      .then((data) => {
        if (data && data.length > 0) {
            setStudenNumbers(data.map(s=> String(s)));
        }
      });
  };

  const handleSelectUser= async (editUser: IUser) => {
    setUserState(editUser);
    if(editUser.userId === userContext.userId){
      setIsLoggedInUserProfile(true);
    }
  }

  const handleClearSearchValue = () => {
    setUserState({} as IUser);
  }

  const editUser= async (editUser: IUser) => {
      setIsLoading(true);
      const {error} =  await accountService.editUser(editUser)
      setIsLoading(false);
      setIsLoggedInUserProfile(false);
     
      if(error){
        setCustomError(error);
      }
      else{
        setUserState({} as IUser);
        navigate("/");  
      }
  };

  const getUser= async () => {
    return await accountService.getUserById(userContext.userId);
  };

  return (
    <>
      {<p style={{marginLeft:"10px"}}><b>Ret profil</b></p>}
      {UserHasRoles(userContext,["Administrator"]) &&  <div>
        <SearchUser onSelectUser={handleSelectUser} onClearSearchValue={handleClearSearchValue}/>
      </div>}
      <br></br>
       {userState.fullName && 
       <UserProfile 
          studentNumbers={studentNumbersState} 
          user={userState} 
          editUser={editUser} 
          customError={customError} 
          isLoading={isLoading}
          isLoggedInUserProfile= {isLoggedInUserProfile}/>}
    </>
  );
}

