import React, { useEffect, useState } from 'react';
import search from '../../image/search.png';
import clear from '../../image/clear.png';
import { IStudent } from '../../models/student';
import { IStudentService, StudentService } from '../../services/student-service';
import './search-student.css';

export interface ISearch{
  onSelectStudent: (student: IStudent) => void;
  onClearSearchValue: () => void;
}

export function SearchStudent(props: ISearch) {

const {onSelectStudent, onClearSearchValue} = props;

 const [studentsState, setStudents] = useState({
    students: [] as IStudent[],
 });

 const [searchValueState, setSearchValue] = useState("");

 const studentService: IStudentService = new StudentService();

 const getStudentsBySearchValue = async (searchValue: any) => {

    const value = searchValue.target.value; 
    
    if(!value){
      setStudents({ students: [] as IStudent[] });
      onClearSearchValue();
    };

    setSearchValue(value);
 };

 const handleKeyPress = async (event: any) => {
  if (event.key === 'Enter') {
    if(searchValueState)
    {
      await studentService
      .getStudentsBySearchValue(searchValueState)
      .then((data) => {
          if (data) {
             setStudents({ students: data });
          }
      });
    }
  }
};

const handleClickSearchIcon = async (event: any) => {
  if(searchValueState)
  {
    await studentService
      .getStudentsBySearchValue(searchValueState)
      .then((data) => {
          if (data) {
             setStudents({ students: data });
          }
      });
  }
};

 const handleSelectStudent = (student: IStudent) => {
    setSearchValue(student.fullName);
    setStudents({ students: [] });
    onSelectStudent(student);
 }

 const handleClearIcon = () => {
  setStudents({ students: [] });
  setSearchValue("");
  onClearSearchValue();
}
  
  return (
    <>
    <div style={{marginLeft:"4px", position: "relative", display:"inline-block", width:"95%", height:"auto"}}>
        <input placeholder='Search student' value={searchValueState} 
        onChange={(value:any) => getStudentsBySearchValue(value)} 
        onKeyDown={handleKeyPress}
        style={{marginLeft:"5px", marginRight:"5px", width:"100%", height:"40px", borderRadius:"6px", borderColor: "#1D5F35"}} 
        type="text"></input>
        <div style={{display:"flex", flexFlow: "row-reverse"}}>
          {<img style={{marginTop: "-32px", height:"25px"}} src={search} onClick={handleClickSearchIcon}></img>}
          <img style={{marginTop: "-32px", marginRight: "10px", height:"25px"}} src={clear} onClick={handleClearIcon} ></img>
        </div>
    </div>
    { studentsState.students.length > 0 && <div style={{marginLeft: "5px", borderStyle: "solid", borderRadius: "4px", 
    borderColor: "lightgray", zIndex:"9999", position: "absolute", backgroundColor: "white", width:"95%",  maxHeight: "300px", overflowY:"auto"}}>
      {studentsState.students.map( student=> {
              return <div key={student.studentId} onClick={()=> handleSelectStudent(student)} className='searchRow' style={{padding: "10px"}}>
                       <label key={student.studentId}>{student.fullName}</label>
                     </div>
      })}
    </div>}
  </>
  );
}

