import { IUser } from "../user/user";
import { BaseApiService, IBaseApiService } from "./base-service";

const url = `api/Account/`;
const createUser = `CreateUser`;
const updateUser = `UpdateUser`;
const getUsersBySearchValue = `GetUsersBySearchValue`;
const isEmailUniq = `IsEmailUniq`;
const getUserById = `GetUserById`;
export interface IAccountService {
    createUser(user: IUser): Promise<any>;
    editUser(user: IUser): Promise<any>;
    getUsersBySearchValue(searchValue: string): Promise<IUser[]>;
    isEmailUniq(emailRequest: any): Promise<boolean>;
    getUserById(userId: string | undefined): Promise<IUser>;
}

export class AccountService implements IAccountService {
  private readonly baseService: IBaseApiService;
  constructor() {
    this.baseService = new BaseApiService();
  }
 
  public async createUser(user: IUser): Promise<any> {
    try {
      const response = await this.baseService.authPost<IUser, any>(
        `${url}${createUser}`,
        user
      );

      if(response === "User is created"){
        return {data: response};
      }

      const errorText = await response.text();
      return { error: `Failed to create user`} ;
      
    } catch (error: any) {
       return { error: `Failed to create user`} ;
    }
  }

  public async editUser(user: IUser): Promise<any> {
    try {
      const response = await this.baseService.authPost<IUser, any>(
        `${url}${updateUser}`,
        user
      );

      if(response === "User is updated"){
        return {data: response};
      }

      const errorText = await response.text();
      return { error: `Failed to update user`};
      
    } catch (error: any) {
       return { error: `Failed to update user`};
    }
  }

  public async getUsersBySearchValue(searchValue: string): Promise<IUser[]> {
    try {
      return await this.baseService.authGet<IUser[]>(
        `${url}${getUsersBySearchValue}/${searchValue}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getUserById(userId: string | undefined): Promise<IUser> {
    try {
      return await this.baseService.authGet<IUser>(
        `${url}${getUserById}/${userId}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async isEmailUniq(emailRequest: any): Promise<boolean> {
    try {
      return await this.baseService.authPost<string | undefined, boolean>(
        `${url}${isEmailUniq}`, emailRequest
      );
    } catch (error: any) {
      return error;
    }
  }

}
