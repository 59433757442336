export const Chapters = [
	{
		"chapterId": "B459AE76-C72D-4118-520B-08DB2F86D5D3",
		"chapternumber": 1,
		"name": "Surah Fatiha",
		"nameArabic": "سُوْرَۃُ الفَاتِحَة",
		"numberOfVerses": 7
	},
	{
		"chapterId": "B8ACA08E-D1E6-43F5-520C-08DB2F86D5D3",
		"chapternumber": 2,
		"name": "Surah Bakarakh",
		"nameArabic": "سُوْرَۃُ البَقَرَة",
		"numberOfVerses": 286
	},
	{
		"chapterId": "B315ACE8-AE78-4B05-520D-08DB2F86D5D3",
		"chapternumber": 3,
		"name": "Surah Alay Imran",
		"nameArabic": "سُوْرَۃ آلِ عِمْرَان",
		"numberOfVerses": 200
	},
	{
		"chapterId": "835D50CC-E6DD-4745-520E-08DB2F86D5D3",
		"chapternumber": 4,
		"name": "Surah Nisa",
		"nameArabic": "سُوْرَۃُ النِّسَاء",
		"numberOfVerses": 176
	},
	{
		"chapterId": "3406476B-B19A-4420-520F-08DB2F86D5D3",
		"chapternumber": 5,
		"name": "Surah Maidah",
		"nameArabic": "سُوْرَۃُ المَائِدَة",
		"numberOfVerses": 120
	},
	{
		"chapterId": "E771D326-7C0F-49D9-5210-08DB2F86D5D3",
		"chapternumber": 6,
		"name": "Surah Anam",
		"nameArabic": "سُوْرَۃُ الأَنْعَام",
		"numberOfVerses": 165
	},
	{
		"chapterId": "938F56E5-63BB-484F-5211-08DB2F86D5D3",
		"chapternumber": 7,
		"name": "Surah A’araf",
		"nameArabic": "سُوْرَۃُ الأَعْرَاف",
		"numberOfVerses": 206
	},
	{
		"chapterId": "CBD6A9BE-F8E4-4D31-5212-08DB2F86D5D3",
		"chapternumber": 8,
		"name": "Surah Anfal",
		"nameArabic": "سُوْرَۃُ الأَنْفَال",
		"numberOfVerses": 75
	},
	{
		"chapterId": "347FB0A2-368F-4542-5213-08DB2F86D5D3",
		"chapternumber": 9,
		"name": "Surah Toba",
		"nameArabic": "سُوْرَۃُ التَّوْبَة",
		"numberOfVerses": 129
	},
	{
		"chapterId": "070F88A7-F98B-4304-5214-08DB2F86D5D3",
		"chapternumber": 10,
		"name": "Surah Younas",
		"nameArabic": "سُوْرَۃ يُونُس",
		"numberOfVerses": 109
	},
	{
		"chapterId": "FE02C4B0-97CF-4F25-5215-08DB2F86D5D3",
		"chapternumber": 11,
		"name": "Surah Hud",
		"nameArabic": "سُوْرَۃ هُود",
		"numberOfVerses": 123
	},
	{
		"chapterId": "DB540F17-48E3-46B8-5216-08DB2F86D5D3",
		"chapternumber": 12,
		"name": "Surah Yousaf",
		"nameArabic": "سُوْرَۃ يُوسُف",
		"numberOfVerses": 111
	},
	{
		"chapterId": "A6F0F581-E552-4D55-5217-08DB2F86D5D3",
		"chapternumber": 13,
		"name": "Surah Ra’ad",
		"nameArabic": "سُوْرَۃ الرَّعْد",
		"numberOfVerses": 43
	},
	{
		"chapterId": "E7D003D0-755A-4E9B-5218-08DB2F86D5D3",
		"chapternumber": 14,
		"name": "Surah Ibrahim",
		"nameArabic": "سُوْرَۃُ إِبْرَاهِيم",
		"numberOfVerses": 52
	},
	{
		"chapterId": "5584D832-1A76-4126-5219-08DB2F86D5D3",
		"chapternumber": 15,
		"name": "Surah Hijr",
		"nameArabic": "سُوْرَۃُ الحِجْر",
		"numberOfVerses": 99
	},
	{
		"chapterId": "75122D4B-733C-4FA2-521A-08DB2F86D5D3",
		"chapternumber": 16,
		"name": "Surah Nahl",
		"nameArabic": "سُوْرَۃُ النَّحْل",
		"numberOfVerses": 128
	},
	{
		"chapterId": "047FB177-E62A-49A9-521B-08DB2F86D5D3",
		"chapternumber": 17,
		"name": "Surah Isara",
		"nameArabic": "سُوْرَۃُ الإِسْرَاء",
		"numberOfVerses": 111
	},
	{
		"chapterId": "A6E50E77-8206-4FB3-521C-08DB2F86D5D3",
		"chapternumber": 18,
		"name": "Surah Kahf",
		"nameArabic": "سُوْرَۃ الكَهْف",
		"numberOfVerses": 110
	},
	{
		"chapterId": "EEE3A12D-3F84-4A48-521D-08DB2F86D5D3",
		"chapternumber": 19,
		"name": "Surah Maryam",
		"nameArabic": "سُوْرَۃ مَرْيَم",
		"numberOfVerses": 98
	},
	{
		"chapterId": "678C0819-C9BB-46C0-521E-08DB2F86D5D3",
		"chapternumber": 20,
		"name": "Surah Taha",
		"nameArabic": "سُوْرَۃ طٰهٰ",
		"numberOfVerses": 135
	},
	{
		"chapterId": "930AA780-F0F6-493A-521F-08DB2F86D5D3",
		"chapternumber": 21,
		"name": "Surah Anbia",
		"nameArabic": "سُوْرَۃُ الأَنْبِيَاء",
		"numberOfVerses": 112
	},
	{
		"chapterId": "9EBDF8AE-FB27-4B26-5220-08DB2F86D5D3",
		"chapternumber": 22,
		"name": "Surah Hajj",
		"nameArabic": "سُوْرَۃ الحَجّ",
		"numberOfVerses": 78
	},
	{
		"chapterId": "BC0F5F96-D121-463E-5221-08DB2F86D5D3",
		"chapternumber": 23,
		"name": "Surah Momineen",
		"nameArabic": "سُوْرَۃُ المُؤْمِنُون",
		"numberOfVerses": 118
	},
	{
		"chapterId": "32E960AF-FBFA-4682-5222-08DB2F86D5D3",
		"chapternumber": 24,
		"name": "Surah Nur",
		"nameArabic": "سُوْرَۃ النُّور",
		"numberOfVerses": 64
	},
	{
		"chapterId": "020A3F82-C0F6-40FC-5223-08DB2F86D5D3",
		"chapternumber": 25,
		"name": "Surah Furqan",
		"nameArabic": "سُوْرَۃ الفُرْقَان",
		"numberOfVerses": 77
	},
	{
		"chapterId": "B69FD7F7-198E-4428-5224-08DB2F86D5D3",
		"chapternumber": 26,
		"name": "Surah Shu’ara",
		"nameArabic": "سُوْرَۃُ الشُّعَرَاء",
		"numberOfVerses": 227
	},
	{
		"chapterId": "FB5C9963-4AE8-4739-5225-08DB2F86D5D3",
		"chapternumber": 27,
		"name": "Surah Namal",
		"nameArabic": "سُوْرَۃُ النَّمْل",
		"numberOfVerses": 93
	},
	{
		"chapterId": "80F48D0F-773B-4785-5226-08DB2F86D5D3",
		"chapternumber": 28,
		"name": "Surah Qasas",
		"nameArabic": "سُوْرَۃُ القَصَص",
		"numberOfVerses": 88
	},
	{
		"chapterId": "0621A671-C14E-452E-5227-08DB2F86D5D3",
		"chapternumber": 29,
		"name": "Surah Ankabut",
		"nameArabic": "سُوْرَۃُ العَنْكَبُوت",
		"numberOfVerses": 69
	},
	{
		"chapterId": "A5ED7DAC-A654-4965-5228-08DB2F86D5D3",
		"chapternumber": 30,
		"name": "Surah Room",
		"nameArabic": "سُوْرَۃ الرُّوم",
		"numberOfVerses": 60
	},
	{
		"chapterId": "A0787060-6F19-4F94-5229-08DB2F86D5D3",
		"chapternumber": 31,
		"name": "Surah Luqman",
		"nameArabic": "سُوْرَۃ لُقْمَان",
		"numberOfVerses": 34
	},
	{
		"chapterId": "0BA6FD37-7723-41B8-522A-08DB2F86D5D3",
		"chapternumber": 32,
		"name": "Surah Sajda",
		"nameArabic": "سُوْرَۃُ السَّجْدَة",
		"numberOfVerses": 30
	},
	{
		"chapterId": "E5318DED-D3A0-4761-522B-08DB2F86D5D3",
		"chapternumber": 33,
		"name": "Surah Ahzaab",
		"nameArabic": "سُوْرَۃُ الأَحْزَاب",
		"numberOfVerses": 73
	},
	{
		"chapterId": "EE4CA792-25B7-4F58-522C-08DB2F86D5D3",
		"chapternumber": 34,
		"name": "Surah Saba",
		"nameArabic": "سُوْرَۃ سَبَأ",
		"numberOfVerses": 54
	},
	{
		"chapterId": "33011187-F521-4C15-522D-08DB2F86D5D3",
		"chapternumber": 35,
		"name": "Surah Fatir",
		"nameArabic": "سُوْرَۃ فَاطِر",
		"numberOfVerses": 45
	},
	{
		"chapterId": "63007C0D-CC12-45DA-522E-08DB2F86D5D3",
		"chapternumber": 36,
		"name": "Surah Ya-Sin",
		"nameArabic": "سُوْرَۃ يٰس",
		"numberOfVerses": 83
	},
	{
		"chapterId": "7C172B9C-53EC-4E25-522F-08DB2F86D5D3",
		"chapternumber": 37,
		"name": "Surah Saaffaat",
		"nameArabic": "سُوْرَۃُ الصَّافَّات",
		"numberOfVerses": 182
	},
	{
		"chapterId": "C53D46AE-B3A3-4383-5230-08DB2F86D5D3",
		"chapternumber": 38,
		"name": "Surah Saad",
		"nameArabic": "سُوْرَۃ ص",
		"numberOfVerses": 88
	},
	{
		"chapterId": "14651C5D-0210-4BC9-5231-08DB2F86D5D3",
		"chapternumber": 39,
		"name": "Surah Zamar",
		"nameArabic": "سُوْرَۃُ الزُّمَر",
		"numberOfVerses": 75
	},
	{
		"chapterId": "12D11FC9-D9BA-4C8C-5232-08DB2F86D5D3",
		"chapternumber": 40,
		"name": "Surah Ghafir",
		"nameArabic": "سُوْرَۃ غَافِر",
		"numberOfVerses": 85
	},
	{
		"chapterId": "59C6F9BB-B382-4800-5233-08DB2F86D5D3",
		"chapternumber": 41,
		"name": "Surah Fussilat",
		"nameArabic": "سُوْرَۃ فُصِّلَت",
		"numberOfVerses": 54
	},
	{
		"chapterId": "461653A5-FC03-4482-5234-08DB2F86D5D3",
		"chapternumber": 42,
		"name": "Surah Shurah",
		"nameArabic": "سُوْرَۃُ الشُّورىٰ",
		"numberOfVerses": 53
	},
	{
		"chapterId": "E1DB40D3-0653-4242-5235-08DB2F86D5D3",
		"chapternumber": 43,
		"name": "Surah Zukhraf",
		"nameArabic": "سُوْرَۃُ الزُّخْرُف",
		"numberOfVerses": 89
	},
	{
		"chapterId": "5E262842-9EC0-4478-5236-08DB2F86D5D3",
		"chapternumber": 44,
		"name": "Surah Dukhan",
		"nameArabic": "سُوْرَۃ الدُّخَان",
		"numberOfVerses": 59
	},
	{
		"chapterId": "4C590AB0-A6CA-42F4-5237-08DB2F86D5D3",
		"chapternumber": 45,
		"name": "Surah Jasiz",
		"nameArabic": "سُوْرَۃ الجَاثِيَة",
		"numberOfVerses": 37
	},
	{
		"chapterId": "33096A2D-02D1-4320-5238-08DB2F86D5D3",
		"chapternumber": 46,
		"name": "Surah Ahqaaf",
		"nameArabic": "سُوْرَۃُ الأَحْقَاف",
		"numberOfVerses": 35
	},
	{
		"chapterId": "C8058C71-625F-4800-5239-08DB2F86D5D3",
		"chapternumber": 47,
		"name": "Surah Muhammad",
		"nameArabic": "سُوْرَۃ مُحَمَّد",
		"numberOfVerses": 38
	},
	{
		"chapterId": "F2A59EC7-A411-49E0-523A-08DB2F86D5D3",
		"chapternumber": 48,
		"name": "Surah Fatah",
		"nameArabic": "سُوْرَۃُ الفَتْح",
		"numberOfVerses": 29
	},
	{
		"chapterId": "F319A093-3002-405E-523B-08DB2F86D5D3",
		"chapternumber": 49,
		"name": "Surah Hujraat",
		"nameArabic": "سُوْرَۃُ الحُجُرَات",
		"numberOfVerses": 18
	},
	{
		"chapterId": "CE9603E7-AD3D-4647-523C-08DB2F86D5D3",
		"chapternumber": 50,
		"name": "Surah Qaaf",
		"nameArabic": "سُوْرَۃ ق",
		"numberOfVerses": 45
	},
	{
		"chapterId": "98AB5662-94F9-4683-523D-08DB2F86D5D3",
		"chapternumber": 51,
		"name": "Sura Az Zariat",
		"nameArabic": "سُوْرَۃُ الذَّارِيَات",
		"numberOfVerses": 60
	},
	{
		"chapterId": "061906BD-36AD-4DDE-523E-08DB2F86D5D3",
		"chapternumber": 52,
		"name": "Surah Toor",
		"nameArabic": "سُوْرَۃ الطُّور",
		"numberOfVerses": 49
	},
	{
		"chapterId": "DC15B91D-F073-4010-523F-08DB2F86D5D3",
		"chapternumber": 53,
		"name": "Surah Najm",
		"nameArabic": "سُوْرَۃُ النَّجْم",
		"numberOfVerses": 62
	},
	{
		"chapterId": "4A0F7802-572C-4611-5240-08DB2F86D5D3",
		"chapternumber": 54,
		"name": "Sura Qamar",
		"nameArabic": "سُوْرَۃُ القَمَر",
		"numberOfVerses": 55
	},
	{
		"chapterId": "B35F1E6B-1086-4E90-5241-08DB2F86D5D3",
		"chapternumber": 55,
		"name": "Sura Rehman",
		"nameArabic": "سُوْرَۃ الرَّحْمَٰن",
		"numberOfVerses": 78
	},
	{
		"chapterId": "83FAEAD1-4C56-4905-5242-08DB2F86D5D3",
		"chapternumber": 56,
		"name": "Sura Waqia",
		"nameArabic": "سُوْرَۃُ الوَاقِعَة",
		"numberOfVerses": 96
	},
	{
		"chapterId": "2FD6FCEA-88D9-4969-5243-08DB2F86D5D3",
		"chapternumber": 57,
		"name": "Sura Hadeed",
		"nameArabic": "سُوْرَۃُ الحَدِيد",
		"numberOfVerses": 29
	},
	{
		"chapterId": "BFA0F4DF-ECC8-4EA1-5244-08DB2F86D5D3",
		"chapternumber": 58,
		"name": "Sura Mujadila",
		"nameArabic": "سُوْرَۃُ المُجَادِلَة",
		"numberOfVerses": 22
	},
	{
		"chapterId": "ABB94814-41FD-498E-5245-08DB2F86D5D3",
		"chapternumber": 59,
		"name": "Sura Hashr",
		"nameArabic": "سُوْرَۃُ الحَشْر",
		"numberOfVerses": 24
	},
	{
		"chapterId": "E243C870-65DA-4F6D-5246-08DB2F86D5D3",
		"chapternumber": 60,
		"name": "Sura Mumtahana",
		"nameArabic": "سُوْرَۃُ المُمْتَحَنَة",
		"numberOfVerses": 13
	},
	{
		"chapterId": "0FA0D77F-7007-4204-5247-08DB2F86D5D3",
		"chapternumber": 61,
		"name": "Sura Saff",
		"nameArabic": "سُوْرَۃُ الصَّفّ",
		"numberOfVerses": 14
	},
	{
		"chapterId": "E63266F0-5FD2-4664-5248-08DB2F86D5D3",
		"chapternumber": 62,
		"name": "Sura Jum’a",
		"nameArabic": "سُوْرَۃُ الجُمُعَة",
		"numberOfVerses": 11
	},
	{
		"chapterId": "8F018D56-3F7D-454A-5249-08DB2F86D5D3",
		"chapternumber": 63,
		"name": "Sura Munafiqeen",
		"nameArabic": "سُوْرَۃُ المُنَافِقُون",
		"numberOfVerses": 11
	},
	{
		"chapterId": "290B05FF-3E79-4D31-524A-08DB2F86D5D3",
		"chapternumber": 64,
		"name": "Sura Taghabun",
		"nameArabic": "سُوْرَۃُ التَّغَابُن",
		"numberOfVerses": 18
	},
	{
		"chapterId": "632D223D-6209-4EAC-524B-08DB2F86D5D3",
		"chapternumber": 65,
		"name": "Sura Talaq",
		"nameArabic": "سُوْرَۃُ الطَّلَاق",
		"numberOfVerses": 12
	},
	{
		"chapterId": "AE96A003-C8D3-4A2C-524C-08DB2F86D5D3",
		"chapternumber": 66,
		"name": "Sura Tehreem",
		"nameArabic": "سُوْرَۃُ التَّحْرِيم",
		"numberOfVerses": 12
	},
	{
		"chapterId": "A8528DEE-E3E3-47F1-524D-08DB2F86D5D3",
		"chapternumber": 67,
		"name": "Sura Mulk",
		"nameArabic": "سُوْرَۃُ المُلْك",
		"numberOfVerses": 30
	},
	{
		"chapterId": "E9CDD0F8-E385-4A05-524E-08DB2F86D5D3",
		"chapternumber": 68,
		"name": "Sura Qalam",
		"nameArabic": "سُوْرَۃ القَلَم",
		"numberOfVerses": 52
	},
	{
		"chapterId": "5F7D4A2D-B8F8-4D5F-524F-08DB2F86D5D3",
		"chapternumber": 69,
		"name": "Sura Haaqqa",
		"nameArabic": "سُوْرَۃ الحَاقَّة",
		"numberOfVerses": 52
	},
	{
		"chapterId": "F2F54F10-2461-444C-5250-08DB2F86D5D3",
		"chapternumber": 70,
		"name": "Sura Ma’arij",
		"nameArabic": "سُوْرَۃُ المَعَارِج",
		"numberOfVerses": 44
	},
	{
		"chapterId": "CA205112-F89D-4B7D-5251-08DB2F86D5D3",
		"chapternumber": 71,
		"name": "chapternumberoh",
		"nameArabic": "سُوْرَۃ نُوح",
		"numberOfVerses": 28
	},
	{
		"chapterId": "AA447213-61CD-402D-5252-08DB2F86D5D3",
		"chapternumber": 72,
		"name": "Sura Jinn",
		"nameArabic": "سُوْرَۃُ الجِنّ",
		"numberOfVerses": 28
	},
	{
		"chapterId": "EFB8792B-109E-40D3-5253-08DB2F86D5D3",
		"chapternumber": 73,
		"name": "Sura Muzzammil",
		"nameArabic": "سُوْرَۃُ المُزَّمِّل",
		"numberOfVerses": 20
	},
	{
		"chapterId": "5C887EE1-EB6F-4ACB-5254-08DB2F86D5D3",
		"chapternumber": 74,
		"name": "Sura Muddassir",
		"nameArabic": "سُوْرَۃُ المُدَّثِّر",
		"numberOfVerses": 56
	},
	{
		"chapterId": "742799CD-ACDC-4A07-5255-08DB2F86D5D3",
		"chapternumber": 75,
		"name": "Sura Qiyamah",
		"nameArabic": "سُوْرَۃُ القِيَامَة",
		"numberOfVerses": 40
	},
	{
		"chapterId": "DE5D8B4A-8E1D-4B1D-5256-08DB2F86D5D3",
		"chapternumber": 76,
		"name": "Sura Al Insaan",
		"nameArabic": "سُوْرَۃُ الإِنْسَان",
		"numberOfVerses": 31
	},
	{
		"chapterId": "B6D5FFB4-AF2F-466B-5257-08DB2F86D5D3",
		"chapternumber": 77,
		"name": "Sura Mursalaat",
		"nameArabic": "سُوْرَۃُ المُرْسَلَات",
		"numberOfVerses": 50
	},
	{
		"chapterId": "1B34DC64-E82F-48D5-5258-08DB2F86D5D3",
		"chapternumber": 78,
		"name": "Sura Naba",
		"nameArabic": "سُوْرَۃُ النَّبَأ",
		"numberOfVerses": 40
	},
	{
		"chapterId": "9B64D193-E8C2-4BA7-5259-08DB2F86D5D3",
		"chapternumber": 79,
		"name": "Sura An Naaziat",
		"nameArabic": "سُوْرَۃُ النَّازِعَات",
		"numberOfVerses": 46
	},
	{
		"chapterId": "A9F05553-445C-47DD-525A-08DB2F86D5D3",
		"chapternumber": 80,
		"name": "Sura Abasa",
		"nameArabic": "سُوْرَۃ عَبَسَ",
		"numberOfVerses": 42
	},
	{
		"chapterId": "F4CA22C0-5E70-433D-525B-08DB2F86D5D3",
		"chapternumber": 81,
		"name": "SuraTakweer",
		"nameArabic": "سُوْرَۃُ التَّكْوِير",
		"numberOfVerses": 29
	},
	{
		"chapterId": "60DAE69C-21DC-4778-525C-08DB2F86D5D3",
		"chapternumber": 82,
		"name": "Sura Infitar",
		"nameArabic": "سُوْرَۃُ الإِنْفِطَار",
		"numberOfVerses": 19
	},
	{
		"chapterId": "A92C590E-9AF1-4C11-525D-08DB2F86D5D3",
		"chapternumber": 83,
		"name": "Sura Mutaffifeen",
		"nameArabic": "سُوْرَۃُ المُطَفِّفِين",
		"numberOfVerses": 36
	},
	{
		"chapterId": "B6F8C297-9098-4C39-525E-08DB2F86D5D3",
		"chapternumber": 84,
		"name": "Sura Inshiqaaq",
		"nameArabic": "سُوْرَۃُ الإِنْشِقَاق",
		"numberOfVerses": 25
	},
	{
		"chapterId": "B8E00083-D125-4F06-525F-08DB2F86D5D3",
		"chapternumber": 85,
		"name": "Sura Burooj",
		"nameArabic": "سُوْرَۃُ البُرُوج",
		"numberOfVerses": 22
	},
	{
		"chapterId": "0C0088B9-7799-4E4E-5260-08DB2F86D5D3",
		"chapternumber": 86,
		"name": "Sura Tariq",
		"nameArabic": "سُوْرَۃُ الطَّارِق",
		"numberOfVerses": 17
	},
	{
		"chapterId": "E41AFE68-2800-4316-5261-08DB2F86D5D3",
		"chapternumber": 87,
		"name": "Sura A’ala",
		"nameArabic": "سُوْرَۃُ الأَعْلَىٰ",
		"numberOfVerses": 19
	},
	{
		"chapterId": "C62C8540-B87F-4867-5262-08DB2F86D5D3",
		"chapternumber": 88,
		"name": "Sura Ghashia",
		"nameArabic": "سُوْرَۃُ الغَاشِيَة",
		"numberOfVerses": 26
	},
	{
		"chapterId": "1D62EFBB-5330-46E3-5263-08DB2F86D5D3",
		"chapternumber": 89,
		"name": "Sura Fajr",
		"nameArabic": "سُوْرَۃُ الفَجْر",
		"numberOfVerses": 30
	},
	{
		"chapterId": "CA4AB922-D25A-4941-5264-08DB2F86D5D3",
		"chapternumber": 90,
		"name": "Sura Balad",
		"nameArabic": "سُوْرَۃُ البَلَد",
		"numberOfVerses": 20
	},
	{
		"chapterId": "2C750D46-F378-4B6E-5265-08DB2F86D5D3",
		"chapternumber": 91,
		"name": "Sura Shams",
		"nameArabic": "سُوْرَۃُ الشَّمْس",
		"numberOfVerses": 15
	},
	{
		"chapterId": "5D7E2511-54FA-4CDA-5266-08DB2F86D5D3",
		"chapternumber": 92,
		"name": "Sura Al Lail",
		"nameArabic": "سُوْرَۃُ اللَّيْل",
		"numberOfVerses": 21
	},
	{
		"chapterId": "267FFB7D-327D-4915-5267-08DB2F86D5D3",
		"chapternumber": 93,
		"name": "Sura Zuha",
		"nameArabic": "سُوْرَۃُ الضُّحَىٰ",
		"numberOfVerses": 11
	},
	{
		"chapterId": "6B6F6E1F-B4EA-4F35-5268-08DB2F86D5D3",
		"chapternumber": 94,
		"name": "Sura Sharh (Inshirah)",
		"nameArabic": "سُوْرَۃُ الشَّرْح",
		"numberOfVerses": 8
	},
	{
		"chapterId": "52957EEF-8611-4638-5269-08DB2F86D5D3",
		"chapternumber": 95,
		"name": "Sura Teen",
		"nameArabic": "سُوْرَۃُ التِّين",
		"numberOfVerses": 8
	},
	{
		"chapterId": "55D60F18-670F-4322-526A-08DB2F86D5D3",
		"chapternumber": 96,
		"name": "Sura Alaq",
		"nameArabic": "سُوْرَۃُ العَلَق",
		"numberOfVerses": 19
	},
	{
		"chapterId": "11CD55FA-57A5-4662-526B-08DB2F86D5D3",
		"chapternumber": 97,
		"name": "Sura Qadr",
		"nameArabic": "سُوْرَۃُ القَدْر",
		"numberOfVerses": 5
	},
	{
		"chapterId": "B992DA16-78FB-4B32-526C-08DB2F86D5D3",
		"chapternumber": 98,
		"name": "Surah Bayyinah",
		"nameArabic": "سُوْرَۃُ البَيِّنَة",
		"numberOfVerses": 8
	},
	{
		"chapterId": "E37B58EF-1023-4404-526D-08DB2F86D5D3",
		"chapternumber": 99,
		"name": "Surah Zalzalah",
		"nameArabic": "سُوْرَۃُ الزَّلْزَلَة",
		"numberOfVerses": 8
	},
	{
		"chapterId": "2F0EFB8E-B8FB-4F15-526E-08DB2F86D5D3",
		"chapternumber": 100,
		"name": "Surah Aadiat",
		"nameArabic": "سُوْرَۃُ العَادِيَات",
		"numberOfVerses": 11
	},
	{
		"chapterId": "BB43DE2D-7543-419E-526F-08DB2F86D5D3",
		"chapternumber": 101,
		"name": "Surah Qariah",
		"nameArabic": "سُوْرَۃُ القَارِعَة",
		"numberOfVerses": 11
	},
	{
		"chapterId": "600DB6BE-D17B-45B5-5270-08DB2F86D5D3",
		"chapternumber": 102,
		"name": "Sura Takasur",
		"nameArabic": "سُوْرَۃُ التَّكَاثُر",
		"numberOfVerses": 8
	},
	{
		"chapterId": "A00D9C87-0D8B-4292-5271-08DB2F86D5D3",
		"chapternumber": 103,
		"name": "Sura Asr",
		"nameArabic": "سُوْرَۃُ العَصْر",
		"numberOfVerses": 3
	},
	{
		"chapterId": "EA54BEFC-23DF-4878-5272-08DB2F86D5D3",
		"chapternumber": 104,
		"name": "Sura Humaza",
		"nameArabic": "سُوْرَۃُ الهُمَزَة",
		"numberOfVerses": 9
	},
	{
		"chapterId": "AC61A923-A6CD-4C3B-5273-08DB2F86D5D3",
		"chapternumber": 105,
		"name": "Sura Feel",
		"nameArabic": "سُوْرَۃُ الفِيل",
		"numberOfVerses": 5
	},
	{
		"chapterId": "75538A4C-573C-447E-5274-08DB2F86D5D3",
		"chapternumber": 106,
		"name": "Sura Quraish",
		"nameArabic": "سُوْرَۃ قُرَيْش",
		"numberOfVerses": 4
	},
	{
		"chapterId": "A412F411-2B02-48E0-5275-08DB2F86D5D3",
		"chapternumber": 107,
		"name": "Sura Maoon",
		"nameArabic": "سُوْرَۃُ المَاعُون",
		"numberOfVerses": 7
	},
	{
		"chapterId": "73119ABA-3C80-497C-5276-08DB2F86D5D3",
		"chapternumber": 108,
		"name": "Sura Kausar",
		"nameArabic": "سُوْرَۃُ الكَوْثَر",
		"numberOfVerses": 3
	},
	{
		"chapterId": "A168809C-5024-4F19-5277-08DB2F86D5D3",
		"chapternumber": 109,
		"name": "Sura Kaafiroon",
		"nameArabic": "سُوْرَۃُ الكَافِرُون",
		"numberOfVerses": 6
	},
	{
		"chapterId": "46C5DA1A-0548-4918-5278-08DB2F86D5D3",
		"chapternumber": 110,
		"name": "Sura Nasr",
		"nameArabic": "سُوْرَۃُ النَّصْر",
		"numberOfVerses": 3
	},
	{
		"chapterId": "F0EDF654-29AC-4EB0-5279-08DB2F86D5D3",
		"chapternumber": 111,
		"name": "Sura Masad",
		"nameArabic": "سُوْرَۃُ المَسَد",
		"numberOfVerses": 5
	},
	{
		"chapterId": "1AA1F974-109F-4E86-527A-08DB2F86D5D3",
		"chapternumber": 112,
		"name": "Sura Ikhlaas",
		"nameArabic": "سُوْرَۃُ الإِخْلَاص",
		"numberOfVerses": 4
	},
	{
		"chapterId": "C0D2F4ED-913D-4B9A-527B-08DB2F86D5D3",
		"chapternumber": 113,
		"name": "Sura Falaq",
		"nameArabic": "سُوْرَۃُ الفَلَق",
		"numberOfVerses": 5
	},
	{
		"chapterId": "26ECE42C-F928-420E-527C-08DB2F86D5D3",
		"chapternumber": 114,
		"name": "Sura Naas",
		"nameArabic": "سُوْرَۃُ النَّاس",
		"numberOfVerses": 6
	}

]