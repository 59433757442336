import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form } from "react-bootstrap";
import { IUser } from "../../../user/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { UserHasRoles } from "../../../user/role";
import { useUserContext } from "../../../user/user-context";
import './user-profile-container.css';

export interface UserProfile {
  user?: IUser;
  studentNumbers: string[];
  createUser?: (user: IUser)=> void;
  editUser?: (user: IUser)=> void;
  customError?: string;
  isLoading: boolean;
  emailIsUnique?: boolean;
  isLoggedInUserProfile?: boolean;
}

interface IUserProfileErrors{
  fullNameError: string;
  emailError: string;
  numberError: string;
  phoneError: string;
  genderError: string;
  roleError: string;
  yearError: string;
}

export function UserProfile(props: UserProfile) {

  const { user, studentNumbers, createUser, editUser, customError, isLoading, emailIsUnique, isLoggedInUserProfile } = props;
 
  const {userContext} = useUserContext();
  const [selectedGender, setGender] = useState("");
  const [selectedYear, setYear] = useState("");
  const [roleOptionsState, setRoleOptions] = useState([]);
  const [userState, setUserState] = useState(user ? user : {fullName:"", email:"", number: "0", phone:""} as IUser);
  const roleOptions = [{"id": "Administrator", "role": "Administrator"}, 
  {"id": "Teacher", "role": "Teacher"},  {"id": "Student", "role": "Student"}];
  const [errorState, setErrorState] = useState({
    error: { } as IUserProfileErrors
   });

  const navigate = useNavigate();

  useEffect(()=>{
    if(user)
    {
      setUserState(user);

      if(user.gender){
          setGender(user.gender.toString());
      }

      if(user.years){
        setYear(user.years);
    }

      if(user.roles){
        setRoleOptions(user.roles as any);
    }
    }
  }, [user])

  useEffect(()=>{

    if(emailIsUnique){
      const errors = {error: {
        ...errorState.error, 
        emailError: "Email is in use",
      }};

      setErrorState(errors);
    }
  }, [emailIsUnique])

  const handleChangeFullname = (event: any) => {
    setUserState({...userState, fullName: event.target.value});
  }

  const handleChangeEmail = (event: any) => {
    setUserState({...userState, email: event.target.value});
  }

  const handleChangeNumber = (event: any) => {
    setUserState({...userState, number: event.target.value ? event.target.value : ""});
  }

  const handleChangePhone = (event: any) => {
    setUserState({...userState, phone: event.target.value});
  }

  const handleSelectGender=(e:any)=>{
    setGender(e);
    setUserState({...userState, gender: e === "1" ? 1 : 2});
  }

  const handleSelectYear=(e:any)=>{
    setYear(e);
    setUserState({...userState, years: e === "1,5" ? "1,5" : e === "3" ? "3" : "5"});
  }

  const handleSelectRole = (e: any)=> {
    const selectedRoles = [].slice.call(e.target.selectedOptions).map((item: any) => item.value) as any;
    setRoleOptions(selectedRoles);
    setUserState({...userState, roles: selectedRoles });
  }

  const getGender = (selectedElement: string)=> {
    switch(selectedElement){
      case "1":
        return "Man";
      case "2":
        return "Women";
    }
  }

  const getYear = (selectedElement: string)=> {
    switch(selectedElement){
      case "1,5":
        return "1,5";
      case "3":
        return "3";
      case "5":
          return "5";
    }
  }


  const handleSaveProfile = () => {
    if(hasError()) return;

    if(user){
      if(editUser){
        editUser(userState);
      }
    }
    else{
      if(createUser){
        createUser(userState);
      }
    }

  }

  const handleCancelSaveProfile = () => {
    navigate("/");  
  }

  function handleChangePassword(event: any) {
    setUserState({...userState, password: event.target.value });
  }

  function handleChangeDeleted() {
    setUserState({...userState, deleted: !userState.deleted});
  }

  const hasError = ()=> {
    let number = (typeof userState.number === "number" || userState.number) ? String(userState.number) : "";

    const errors = {error: {
      ...errorState.error, 
      fullNameError: !userState.fullName ? "Navn er ikke gyldigt" : "",
      emailError: (!userState.email || !isValidEmail(userState.email)) ? "Email er ikke gyldig" : "",
      numberError:( number === "" || !isValidNumber(number) ||
      (UserHasRoles(userState,["Student"])  && 
      String(user?.number) != number &&
      studentNumberIsInUse(number))) ? "Elev nummber er ikke gyldigt" : "",
       phoneError: (!userState.phone || !isValidDanishPhoneNumber(userState.phone)) ? "Telefon nr. er ikke gyldigt" : "",
       genderError: (!userState.gender || !(userState.gender === 1 || userState.gender === 2)) ? "Køn er ikke valgt" : "",
       yearError: (UserHasRoles(userState,["Student"]) && (!userState.years || 
        !(userState.years === "1,5" || 
        userState.years === "3" ||
        userState.years === "5"))) ? "Antal år er ikke valgt" : "",
       roleError: (!userState.roles || userState.roles.length === 0) ? "Role erikke valgt" : ""
     }};

    setErrorState(errors);

    if(errors.error.fullNameError || errors.error.emailError ||errors.error.numberError ||
      errors.error.phoneError || errors.error.genderError || errors.error.yearError || errors.error.roleError){
      return true;
    }

    return false;
    
  }

  const studentNumberIsInUse = (studentNumber: string)=> {
    return studentNumbers.includes(studentNumber);
  }

  function isValidNumber(value: any) {
    if(!value) return false;

    return Number.isInteger(+value);
  }

  const isValidEmail = (email:any) => {
    // Email regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function isValidDanishPhoneNumber(phoneNumber: any) {
    // Danish phone number regex pattern
    const danishPhoneNumberRegex = /^(?:\+45|0045)?(?:\s*\d){8}$/;
    return danishPhoneNumberRegex.test(phoneNumber);
  }

  function userProfileRender() {
  
    return (
        <>
          {<p style={{marginLeft:"40px"}}><b>Opret profil</b></p>}
          <div style={{height:"calc(100vh - 200px)", overflowY:"auto"}}>
            <div  style={{display:"table", marginLeft:"10%", width:"80%", marginTop:"0px"}}>
                <label style={{marginBottom: "5px", marginLeft:"4px"}}>Navn</label>
                <br></br>
                <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
                  <input placeholder='Fullname' 
                    required
                    id="fullname"
                    onChange={handleChangeFullname} 
                    autoFocus
                    value={userState.fullName}
                    style={{
                      marginLeft:"5px", 
                      marginRight:"5px", 
                      width:"100%", 
                      height:"40px", 
                      borderRadius:"6px"}} 
                    type="text">
                  </input>
                  {errorState.error.fullNameError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.fullNameError}</label>}
                </div>
                <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
                  <label style={{marginBottom: "5px", marginLeft:"4px"}}>Email</label>
                  <input placeholder='Email' 
                    required
                    id="email"
                    onChange={handleChangeEmail} 
                    value={userState.email}
                    style={{
                      marginLeft:"5px", 
                      marginRight:"5px", 
                      width:"100%", 
                      height:"40px", 
                      borderRadius:"6px", 
                    }} 
                    type="text">
                  </input>
                  {errorState.error.emailError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.emailError}</label>}
                </div>
                {isLoggedInUserProfile && <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom: "10px"}}>
                  <label style={{marginBottom: "5px", marginLeft:"4px"}}>Password</label>
                  <input placeholder='Password' 
                    required
                    id="password"
                    onChange={handleChangePassword} 
                    value={userState.password}
                    style={{
                        marginLeft:"5px", 
                        marginRight:"5px", 
                        width:"100%", 
                        height:"40px", 
                        borderRadius:"6px", 
                        borderColor: "#1D5F35"}} 
                    type="password">
                  </input>
               </div>}
               <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
                  <label style={{marginBottom: "5px", marginLeft:"4px"}}>Telefon nr.</label>
                  <input placeholder='Phone' 
                    required
                    id="phone"
                    onChange={handleChangePhone} 
                    value={userState.phone}
                    style={{
                      marginLeft:"5px", 
                      marginRight:"5px", 
                      width:"100%", 
                      height:"40px", 
                      borderRadius:"6px"}} 
                    type="number">
                  </input>
                  {errorState.error.phoneError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.phoneError}</label>}
                </div>
                {UserHasRoles(userContext,["Administrator"]) &&  <div style={{marginLeft: "6px", marginBottom: "20px"}}>
                    <label style={{marginBottom: "10px"}}>Roller
                    </label>
                    {  <Form.Group as={Col} controlId="my_multiselect_field1">
                      <Form.Control as="select" multiple value={roleOptionsState} onChange={handleSelectRole}>
                      {roleOptions.map( role => {
                        return <option key={role.id} value={role.id}>{role.role}</option>
                      })}
                      </Form.Control>
                    </Form.Group>}
                {errorState.error.roleError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.roleError}</label>}
                </div>}
               { ((UserHasRoles(userContext,["Administrator"]) && userState.roles?.includes("Student") && editUser) || 
               !userState.roles || (userState.roles?.includes("Student") && createUser)) 
               && <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
                  <label style={{marginBottom: "5px", marginLeft:"4px"}}>Elev nr.</label>
                  <input placeholder='Number' 
                    required
                    id="number"
                    onChange={handleChangeNumber} 
                    value={userState.number}
                    style={{
                      marginLeft:"5px", 
                      marginRight:"5px", 
                      width:"100%", 
                      height:"40px", 
                      borderRadius:"6px", 
                    }} 
                    type="text">
                  </input>
                  {errorState.error.numberError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.numberError}</label>}
                </div>}
                {((UserHasRoles(userContext,["Administrator"]) && userState.roles?.includes("Student") && editUser) || 
               !userState.roles || (userState.roles?.includes("Student") && createUser)) 
               && <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
                  <label style={{marginBottom: "5px", marginLeft:"5px"}}>Anatal år</label>
                  { <DropdownButton
                    onSelect={handleSelectYear} className="custom-dropdown" style={{marginLeft: "5px", marginTop: "0px"}} 
                    key="dropdownkey" 
                    id="dropdown-basic-button" title={!selectedYear ? "Year" : getYear(selectedYear)}>
                      <Dropdown.Item eventKey="1,5">1,5</Dropdown.Item>
                      <Dropdown.Item eventKey="3">3</Dropdown.Item>
                      <Dropdown.Item eventKey="5">5</Dropdown.Item>
                    </DropdownButton>
                }
                {errorState.error.yearError && <label style={{color:"red", marginLeft:"5px"}}>{errorState.error.yearError}</label>}
                </div>}
               {UserHasRoles(userContext,["Administrator"]) &&  <div style={{position: "relative", display:"inline-block", width:"95%", marginBottom:"20px"}}>
                  <label style={{marginBottom: "5px", marginLeft:"5px"}}>Køn</label>
                  { <DropdownButton
                    onSelect={handleSelectGender} className="custom-dropdown" style={{marginLeft: "5px", marginTop: "0px"}} 
                    key="dropdownkey" 
                    id="dropdown-basic-button" title={!selectedGender ? "Gender" : getGender(selectedGender)}>
                      <Dropdown.Item eventKey="1">Man</Dropdown.Item>
                      <Dropdown.Item eventKey="2">Women</Dropdown.Item>
                    </DropdownButton>
                    }
                {errorState.error.genderError && <label style={{color:"red", marginLeft:"7px"}}>{errorState.error.genderError}</label>}
                </div>}
                {customError && <label style={{color:"red", marginLeft:"7px"}}>{customError}</label>}
                <br></br>
                {UserHasRoles(userContext,["Administrator"]) && editUser && <div>
                  <label style={{marginBottom: "5px", marginLeft:"7px"}}>Deaktiver bruger</label>
                  <Form>
                    <Form.Check 
                        checked={userState.deleted} 
                        onChange={handleChangeDeleted} 
                        type="checkbox" 
                        style={{marginLeft:"10px"}}/>
                  </Form>
                </div>}
                <Button
                  color="primary"
                  onClick={handleSaveProfile}
                  style={{
                    backgroundColor:"#1D5F35",
                    marginTop:"20px", 
                    marginLeft:"7px", 
                    }}
                >
                  {isLoading ?  (
                      <>
                      <FontAwesomeIcon icon={faSpinner} spin />
                      <span style={{ marginLeft: '0.5rem' }}>Gemmer...</span>
                    </>
                  ) : (
                    'Save profile'
                  )}
                </Button>
                <Button
                  color="primary"
                  onClick={handleCancelSaveProfile}
                  style={{
                    backgroundColor:"#1D5F35",
                    marginTop:"20px", 
                    marginLeft:"5px", 
                    }}>
                    Fortryd
                </Button>
            </div>
          </div>
        </>

    );
  }

  return userProfileRender();
}
