import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AgoraContainer } from './agora-container';
import { IMemorizationSession } from '../../models/memorization-session';
import { Table } from 'react-bootstrap';
import { MemorizationType } from '../../enums/status-enums';
import { IMemorizationChapter } from '../../models/memorization-chapter';

export interface IAgoraModal {
  showModal: boolean;
  channelName: string;
  onHideModal: () => void;
  memorizationChapters: IMemorizationChapter[];
}

function AgoraModal(props: IAgoraModal) {

    const {showModal, channelName, memorizationChapters, onHideModal} = props;

    const [leaveChannelState, setLeaveChannel] = useState(false);

    const onCloseModal = () => {
        onHideModal();
        setLeaveChannel(true);
    };

    const handleLeaveChannel = () => {
        setLeaveChannel(false);
        onHideModal();
    }

    return (
      <>
        <Modal show={showModal} onHide={onCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Hifd/Murajara session</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{height:"80vh"}}>
              <AgoraContainer 
                channelName={channelName}
                leaveChannel={leaveChannelState}
                onLeaveChannel={handleLeaveChannel}/>
                <div style={{marginTop:"50px"}}>
                  {memorizationChapters.length > 0 &&  <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Surah</th>
                          <th>Fra</th>
                          <th>Til</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tbody>
                      {memorizationChapters.map((memorizationChapter, index) => (
                                <tr key={index}>
                                    <td>
                                      {`${memorizationChapter.name} (${memorizationChapter.nameArabic})`}
                                    </td>
                                    <td>
                                      {memorizationChapter.fromAyah}
                                    </td>
                                    <td>
                                      {memorizationChapter.toAyah}
                                    </td>
                                    <td>
                                      {memorizationChapter.memorizationType === MemorizationType.Hifz ? "Hifz" : "Murajara"}
                                    </td>
                                </tr>
                              ))}
                      </tbody>
                    </Table>}
                </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
}

export default AgoraModal;