import { faCircleNotch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

interface ButtonPosition {
  top: number;
  left: number;
}

interface RefreshButtonProps {
  showRefreshIcon: boolean;
}

export const RefreshButton: React.FC<RefreshButtonProps> = ({ showRefreshIcon }) => {
  //const [buttonPosition, setButtonPosition] = useState<ButtonPosition>({ top: 0, left: 50 });

  const handleTouchMove = (event: TouchEvent) => {
    const touch = event.touches[0];
    //setButtonPosition({ top: touch.clientY, left: touch.clientX });
  };

  useEffect(() => {
    document.addEventListener('touchmove', handleTouchMove);

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  

  return (
    <div
      style={{
        display: showRefreshIcon ? 'block' : 'none',
        //top: buttonPosition.top,
        //left: buttonPosition.left,
        marginLeft:"0%",
        marginTop: "0%",
      }}
    >
       <FontAwesomeIcon size="2x"  icon={faCircleNotch } spin />
    </div>
  );
};