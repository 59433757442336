import React, { useEffect, useRef, useState } from 'react';
import { Calendar, View, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { GetDatePartFromString } from '../../../helpers/date-helper';

// Define the event interface
interface Event {
  title: any;
  start: Date;
  end: Date;
}

export interface AbsenceCalendar{
    completedDates: Date[];
}

const localizer = momentLocalizer(moment);

export function AbsenceCalendar(props: AbsenceCalendar) {

    const {completedDates} = props;

    const [events, setEvents] = useState<Event[]>([]);

    useEffect(()=>{
      setMemorizationEvents();
    },[completedDates]);

    const ensureWhiteBackground = () => {
        return {
          style: {
            backgroundColor: 'white',
          },
        };
    }

    const setMemorizationEvents = () => {
      let completedMemorizationEvents: Event[] = [];
      let dates: Date[] = [];
      completedDates.forEach(completedDate=>{ 
          dates.push(GetDatePartFromString(completedDate.toString()));
      });

      const uniqueDates = [] as Date[];
        for (let date of dates) {
          if (!uniqueDates.find(uniqueDate => uniqueDate.getTime() === date.getTime())) {
            uniqueDates.push(date);
          }
      }

      uniqueDates.forEach(completedDate=>{ 
        completedMemorizationEvents.push({title: <FontAwesomeIcon icon={faCheck} />, start: completedDate, end:completedDate} as Event);
      });


      setEvents(completedMemorizationEvents);
    }

    return (
        <>
        <div >
          <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                views={['month']}
                onSelectEvent={(event) => {}}
                dayPropGetter={ensureWhiteBackground}
                style={{  height: 'calc(100vh - 200px)'}}
            />
        </div>
        </>
    );
}

