import { IMemorization } from "../models/memorization";
import { BaseApiService, IBaseApiService } from "./base-service";

const url = `api/Memorization/`;
const getMemorizationsByStudentId = `GetMemorizationsByStudentId`;
const getOngoingMemorizationByStudentId = `GetOngoingMemorizationByStudentId`;
const createMemorizationHomework = "CreateMemorizationHomework";
const updateMemorizationHomework = "UpdateMemorizationHomework";
const completeMemorizationHomework = "CompleteMemorizationHomework";
const getCompletedMemorizationDatesByStudentId = `GetCompletedMemorizationDatesByStudentId`;
const getMemorizationsByDateInterval = `GetMemorizationsByDateInterval`;

export interface IMemorizationService {
    getMemorizationsByStudentId(studentId: string): Promise<IMemorization[]>;
    getOngoingMemorizationByStudentId(studentId: string): Promise<IMemorization>;
    createMemorizationHomework(memorization: IMemorization) : Promise<IMemorization[]>;
    updateMemorizationHomework(memorization: IMemorization) : Promise<IMemorization[]>;
    completeMemorizationHomework(memorization: IMemorization) : Promise<IMemorization[]>;
    getCompletedMemorizationDatesByStudentId(studentId: string): Promise<Date[]>;
    getMemorizationsByDateInterval(startDate: Date | null, endDate: Date | null): Promise<IMemorization[]>;
}

export class MemorizationService implements IMemorizationService {
  private readonly baseService: IBaseApiService;
  constructor() {
    this.baseService = new BaseApiService();
  }
 
  public async getMemorizationsByStudentId(studentId: string): Promise<IMemorization[]> {
    try {
      return await this.baseService.authGet<IMemorization[]>(
        `${url}${getMemorizationsByStudentId}/${studentId}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getOngoingMemorizationByStudentId(studentId: string): Promise<IMemorization> {
    try {
      return await this.baseService.authGet<IMemorization>(
        `${url}${getOngoingMemorizationByStudentId}/${studentId}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getCompletedMemorizationDatesByStudentId(studentId: string): Promise<Date[]> {
    try {
      return await this.baseService.authGet<Date[]>(
        `${url}${getCompletedMemorizationDatesByStudentId}/${studentId}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getMemorizationsByDateInterval(startDate: Date | null, endDate: Date | null): Promise<IMemorization[]> {
    try {
      return await this.baseService.authPost<any,IMemorization[]>(
        `${url}${getMemorizationsByDateInterval}`,
        {startDate, endDate}
      );
    } catch (error: any) {
      return error;
    }
  }

  public async createMemorizationHomework(memorization: IMemorization) : Promise<IMemorization[]> {
    try {
      return await this.baseService.authPost<IMemorization, IMemorization[]>(
        `${url}${createMemorizationHomework}`,
        memorization
      );
    } catch (error: any) {
      return error;
    }
  }

  public async updateMemorizationHomework(memorization: IMemorization) : Promise<IMemorization[]> {
    try {
      return await this.baseService.authPost<IMemorization, IMemorization[]>(
        `${url}${updateMemorizationHomework}`,
        memorization
      );
    } catch (error: any) {
      return error;
    }
  }

  public async completeMemorizationHomework(memorization: IMemorization) : Promise<IMemorization[]> {
    try {
      return await this.baseService.authPost<IMemorization, IMemorization[]>(
        `${url}${completeMemorizationHomework}`,
        memorization
      );
    } catch (error: any) {
      return error;
    }
  }

  public async deleteMemorizationHomework(memorization: IMemorization) : Promise<IMemorization[]> {
    try {
      return await this.baseService.authPost<IMemorization, IMemorization[]>(
        `${url}${updateMemorizationHomework}`,
        memorization
      );
    } catch (error: any) {
      return error;
    }
  }

}
