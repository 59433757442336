import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { IPaymentRequest } from '../../models/payment-request';
import {  GetDatePartFromString, GetDateString } from '../../helpers/date-helper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Dropdown, DropdownButton} from "react-bootstrap";
import { IPayment } from "../../models/payment";


interface IUpdatePaymentErrors{
  paymentDateError: string;
}

export interface IUpdatePaymentModal {
   onUpdatePayment: (payment: IPaymentRequest) => void;
   onDeletePayment: (paymentId: string)=> void;
   onHideModal: () => void;
   payment: IPayment;
   show: boolean;
}

export function UpdatePaymentModal(props: IUpdatePaymentModal) {

   const {payment, show, onUpdatePayment, onHideModal, onDeletePayment} = props;

   const handleClose = () => 
   {
      onHideModal();
   };

  const [selectedPaymentDate, setSelectedPaymentDate] = useState<Date | null>( payment.date ? GetDatePartFromString(payment.date.toString()) : null);

  const [errorState, setErrorState] = useState({
    error: { } as IUpdatePaymentErrors
  });

  useEffect(()=>{
      if(payment.date){
      setSelectedPaymentDate(GetDatePartFromString(payment.date.toString()));
      }
  }, [payment.date]);


  const handlePaymentDateChange = (date: Date) => {
    setSelectedPaymentDate(date);
  };

  const handleUpdatePayment = () => {

    if(hasError()) return;

    onUpdatePayment({
      paymentId: payment.paymentId,
      studentId: payment.studentId,
      date: selectedPaymentDate ? GetDateString(selectedPaymentDate) : null,
    } as IPaymentRequest);
};

const handleDeletePayment = () => {

  if(hasError()) return;

  onDeletePayment(payment.paymentId);
};


const hasError = ()=> {

  const errors = {error: {
    ...errorState.error, 
     paymentDateError: !selectedPaymentDate ? "Paymentdate is required" : ""
   }};

  setErrorState(errors);

  if(errors.error.paymentDateError){
    return true;
  }

  return false;
}


return (
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Hifz homework</Modal.Title>
          </Modal.Header>
          <Modal.Body>
         {
          <div style={{marginTop:"15px"}}>
            <div style={{display:"flex"}}>
                <label style={{marginTop: "5px", marginLeft:"12px"}}>Payment day</label>
                <div style={{width: "130px", marginLeft:"10px", marginBottom: "5px"}}>
                  <DatePicker
                      key="1"
                      title="Startdate"
                      selected={selectedPaymentDate}
                      onChange={handlePaymentDateChange}
                      onSelect={handlePaymentDateChange}
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                    />
                </div>
            </div>
            {errorState.error.paymentDateError && <label style={{color:"red", marginLeft:"10px", marginBottom:"10px"}}>{errorState.error.paymentDateError}</label>}
        </div>
        }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary"  style={{backgroundColor:"#1D5F35"}} onClick={handleClose}>
              Cancel
            </Button>
             <Button variant="primary" style={{backgroundColor:"#1D5F35"}} onClick={handleUpdatePayment}>
              Update payment
            </Button>
            <Button variant="primary" style={{backgroundColor:"#1D5F35"}} onClick={handleDeletePayment}>
              Delete payment
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}
