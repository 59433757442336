import * as React from "react";
import { useState } from "react";
import { IUser } from "../../user/user";
import { AuthService, IAuthService } from "./auth-service";

export function Register() {
  //const history = useHistory();

  const authService: IAuthService = new AuthService();

  const [registerState, setRegisterState] = useState({} as IUser);

  async function handleRegister() {
    const localRegisterState = {
      ...registerState,
      role: ["Admin", "DrivingInstructor"],
    };
    const response = await authService.register(localRegisterState);

    console.log(response);

    if (response.result === "200") {
      //history.push("/lovpakker");
    }
  }

  function handleChangeEmail(event: any) {
    setRegisterState({ ...registerState, email: event.target.value });
  }

  function handleChangePassword(event: any) {
    setRegisterState({ ...registerState, password: event.target.value });
  }

  function handleChangeFirstName(event: any) {
    setRegisterState({ ...registerState, name: event.target.value });
  }

  function handleChangeLastName(event: any) {
    setRegisterState({ ...registerState, surname: event.target.value });
  }

  return (
      <div >
        <label >
          Tilmeld 
        </label>
        <form  noValidate>
         <div>
              <input
                autoComplete="fname"
                name="firstName"
                id="firstName"
                autoFocus
                onChange={handleChangeFirstName}
              />
            </div>
            <div>
              <input
                id="lastName"
                name="lastName"
                autoComplete="lname"
                onChange={handleChangeLastName}
              />
            </div>
            <div>
              <input
                required
                id="email"
                name="email"
                autoComplete="email"
                onChange={handleChangeEmail}
              />
            </div>
            <div>
              <input
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChangePassword}
              />
            </div>
          <button
            color="primary"
            onClick={handleRegister}
          >
            Tilmeld dig
          </button>
        </form>
      </div>
  );
}