export const GetDateString =  (dateIn: Date) => {
    var yyyy = dateIn.getFullYear();
    var mm = dateIn.getMonth() + 1; // getMonth() is zero-based
    var dd = dateIn.getDate();
    return `${dd}/${mm}/${yyyy}`;
  }

  export const  GetDateByString=  (dateIn: string) => {
    var yyyy = dateIn.slice(0,4);
    var mm = dateIn.slice(5,7)
    var dd = dateIn.slice(8,10);
    return `${dd}/${mm}/${yyyy}`;
  }

  export const  GetDateTimeByString=  (dateIn: string) => {
    var yyyy = dateIn.slice(0,4);
    var mm = dateIn.slice(5,7)
    var dd = dateIn.slice(8,10);
    return `${dd}/${mm}/${yyyy}`;
  }

  export const  GetFormattedTimeString=  (date: string) => {
    var hour = date.slice(11,13);
    var minute = date.slice(14,16);
    return `${hour}:${minute}`;
}

  export const  GetYearFromDateString=  (dateIn: string) => {
    const date = new Date(dateIn);
    const year = date.getFullYear();
    return year;
  }

  export const  GetMonthNameFromDateString=  (dateIn: string) => {
    const parsedDate = new Date(dateIn);
    const monthName = parsedDate.toLocaleString('default', { month: 'long' });
    return monthName;
  }

  export const IsMemorizationClosedForChanges = (completedDate: Date)=> {
    //var OneDay = new Date().getTime() + (1 * 24 * 60 * 60 * 1000)
    //var nn = GetDateByString(memorization.completedDate.toString());
    //GetDateByString
    //console.log(typeof Date.parse(memorization.completedDate.toString()));
    return completedDate ? true : false; // && OneDay < nn.getTime();
  }

  export const GetDatePartFromString= (dateString: string) => {
    const datePartString = dateString.substr(0, 10);
    return new Date(datePartString);
  }

  export const GetCurrentDate = ()=> {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDateOfMonth = currentDate.getDate();
    const newDate = new Date();
    newDate.setFullYear(currentYear);
    newDate.setMonth(currentMonth);
    newDate.setDate(currentDateOfMonth);

    return newDate;
  }

  export const GetDate = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const dateOfMonth = date.getDate();
    const newDate = new Date();
    newDate.setFullYear(year);
    newDate.setMonth(month);
    newDate.setDate(dateOfMonth);

    return newDate;
  }