import { ISearchStudentWithMemorization } from "../models/search-student-with-memorization";
import { IStudent } from "../models/student";
import { BaseApiService, IBaseApiService } from "./base-service";

const url = `api/Student/`;
const getStudentsBySearchValue = "GetStudentsBySearchValue";
const getStudentNumbers = `GetStudentNumbers`;
const getStudentWithMemorizationsByDateInterval = `GetStudentWithMemorizationsByDateInterval`;
export interface IStudentService {
    getStudentsBySearchValue(searchValue: string): Promise<IStudent[]>;
    getStudentNumbers(): Promise<number[]>;
    getStudentWithMemorizationsByDateInterval(startDate: string, endDate: string): Promise<ISearchStudentWithMemorization[]>;
}

export class StudentService implements IStudentService {
  private readonly baseService: IBaseApiService;
  constructor() {
    this.baseService = new BaseApiService();
  }
 
  public async getStudentsBySearchValue(searchValue: string): Promise<IStudent[]> {
    try {
      return await this.baseService.authGet<IStudent[]>(
        `${url}${getStudentsBySearchValue}/${searchValue}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getStudentNumbers(): Promise<number[]> {
    try {
      return await this.baseService.authGet<number[]>(
        `${url}${getStudentNumbers}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getStudentWithMemorizationsByDateInterval(startDate: string, endDate: string): Promise<ISearchStudentWithMemorization[]> {
    
    try {
      return await this.baseService.authPost<any,ISearchStudentWithMemorization[]>(
        `${url}${getStudentWithMemorizationsByDateInterval}`,
        {startDate, endDate}
      );
    } catch (error: any) {
      return error;
    }
  }

}
