import { IMemorization } from "../models/memorization";
import { IMemorizationChapter } from "../models/memorization-chapter";

  export const SortMemorizationType = (a:IMemorizationChapter,b: IMemorizationChapter) => {  
    return a.memorizationType - b.memorizationType;
  }; 

  export const SortDateDescending = (a:IMemorization,b: IMemorization) => {  
    var dateA = new Date(a.createdAt).getTime();
    var dateB = new Date(b.createdAt).getTime();
    return dateA < dateB ? 1 : -1;  
  }; 