import { useEffect, useState } from 'react';

export const useTouchScreenLongPressEffect = () => {
  const [showRefreshIcon, setShowRefreshIcon] = useState(false);
  const [touchStarted, setTouchStarted] = useState(false);

  useEffect(() => {
    let touchTimer: any;

    const handleTouchStart = () => {
      touchTimer = setTimeout(() => {
        setShowRefreshIcon(true);
        setTouchStarted(true);
      }, 2000);
    };

    const handleTouchEnd = () => {
      clearTimeout(touchTimer);
      if (touchStarted) {
        setShowRefreshIcon(false);
        setTouchStarted(false);
        window.location.reload();
      }
    };

    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [touchStarted]);

  return touchStarted;
};