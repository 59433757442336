import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { MemorizationType } from "../../enums/status-enums";
import { SortMemorizationType } from "../../helpers/sort-helper";
import { IMemorizationChapter } from "../../models/memorization-chapter";

export interface IMemorizationChapterViewTable{
  memorizationChapters: IMemorizationChapter[];
}

export function MemorizationChapterViewTable(props: IMemorizationChapterViewTable){

  const {memorizationChapters} = props;

  const [memorizationChaptersState, setMemorizationChapters] = useState(memorizationChapters 
    ? memorizationChapters : [] as IMemorizationChapter[] );

  useEffect(()=>{
    if(memorizationChapters){
      setMemorizationChapters(memorizationChapters);
    }
  },[memorizationChapters]);

  return (
    <>
           {memorizationChaptersState.length > 0 &&  <Table striped bordered hover>
            <thead>
              <tr>
                <th>Surah</th>
                <th>From</th>
                <th>To</th>
                <th>Type</th>
              </tr>
            </thead>
            <tbody>
            {memorizationChaptersState.sort(SortMemorizationType).map((memorizationChapter, index) => (
                      <tr  onClick={()=> {}} key={index}>
                          <td>
                            {memorizationChapter.chapterDisplayName}
                          </td>
                          <td>
                            {memorizationChapter.fromAyah}
                          </td>
                          <td>
                            {memorizationChapter.toAyah}
                          </td>
                          <td>
                            {memorizationChapter.memorizationType === MemorizationType.Hifz ? "Hifz" : "Murajara"}
                          </td>
                      </tr>
                    ))}
            </tbody>
          </Table>
         } 
       
    </>
  );
}

