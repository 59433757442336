import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import decode from "jwt-decode";
import { getAuthDataFromLocalStorage } from "./model/auth-utils";
import { UserProvider } from "../../user/user-context";
import { IUser } from "../../user/user";

export function AuthContainer() {
  
  const location = useLocation();
  
  function isAuthenticated() {
    const authData = getAuthDataFromLocalStorage();
    if (!authData.accessToken || !authData.refreshToken) {
      return false;
    }

    try {
      const { exp } = decode(authData.accessToken) as any;

      if (exp < new Date().getTime() / 1000) {
        return false;
      }
    } catch (e) {
      return false;
    }

    return true;
  }

  const mapAuthToUser = () => {
    const authData = getAuthDataFromLocalStorage();
    
    return {
             userId: authData.userId, 
             email: authData.email, 
             fullName: authData.fullName,
             teacherId: authData.teacherId,
             studentId: authData.studentId,
             roles: authData.roles,
             number: authData.number.toString(),
             phone: authData.phone,
             years: authData.years

          } as IUser;   
  }

  const navigateTo = ()=> {
    if(location.pathname === "/resetpassword") return <Navigate to="/resetpassword"  />;

    if(location.pathname === "/terms") return <Navigate to="/terms"  />;

    return <Navigate to="/login"  />;
  }

  return (
    <>
      {
        isAuthenticated() 
        ? <UserProvider userContext={mapAuthToUser()}>
              <Outlet/> 
          </UserProvider>
        : navigateTo()
      }
    </>
  );
}
