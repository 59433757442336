import { IUser } from "./user";


export enum Roles {
  Admin = "Systemadministrator",
  Teacher = "Teacher",
  Student = "Student",
}

export const UserHasRoles = (user: IUser, roles: string[]) => {
  return user && user.roles && user.roles.some((x) => roles.includes(x));
}