import { useEffect } from "react";
import { useNavigate} from "react-router-dom";

export interface Logout {}

export function Logout() {

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("authData");
    navigate('/login');
  }, [navigate]);
 
  const logoutRender = ()=> {
      return <div></div>
  }

  return logoutRender();
}
