import { ICompleteMemorizationSessionRequest } from "../models/complete-memorization-session-request";
import { IMemorizationSession } from "../models/memorization-session";
import { BaseApiService, IBaseApiService } from "./base-service";

const url = `api/MemorizationSession/`;
const getMemorizationSessions = `GetMemorizationSessions`;
const assignMemorizationSessionToTeacher = `AssignMemorizationSessionToTeacher`;
const bookMemorizationSessionByStudentId = `BookMemorizationSessionByStudentId`;
const addMemorizationSessionBackToQueue = `AddMemorizationSessionBackToQueue`;
const completeMemorizationSession = `CompleteMemorizationSession`;
const deleteMemorizationSession = `DeleteMemorizationSession`;
const getMemorizationSessionsAssignedToTeacher = `GetMemorizationSessionsAssignedToTeacher`;

export interface IMemorizationSessionService {
    getMemorizationSessions(): Promise<IMemorizationSession[]>;
    getMemorizationSessionsAssignedToTeacher(teacherId: string): Promise<IMemorizationSession[]>;
    bookMemorizationSessionByStudentId(studentId: string, online: boolean): Promise<IMemorizationSession[]>;
    addMemorizationSessionBackToQueueAsync(memorizationSessionId: string): Promise<IMemorizationSession[]>;
    assignMemorizationSessionToTeacher(memorizationSessionId: string, teacherId: string): Promise<IMemorizationSession[]>;
    completeMemorizationSession(completeMemorizationSessionRequest: ICompleteMemorizationSessionRequest): Promise<IMemorizationSession[]>;
    deleteMemorizationSession(memorizationSessionId: string): Promise<IMemorizationSession[]>;
}

export class MemorizationSessionService implements IMemorizationSessionService {
  private readonly baseService: IBaseApiService;
  constructor() {
    this.baseService = new BaseApiService();
  }
 
  public async getMemorizationSessions(): Promise<IMemorizationSession[]> {
    try {
      return await this.baseService.authGet<IMemorizationSession[]>(
        `${url}${getMemorizationSessions}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async getMemorizationSessionsAssignedToTeacher(teacherId: string): Promise<IMemorizationSession[]> {
    try {
      return await this.baseService.authGet<IMemorizationSession[]>(
        `${url}${getMemorizationSessionsAssignedToTeacher}/${teacherId}`
      );
    } catch (error: any) {
      return error;
    }
  }

 
  public async bookMemorizationSessionByStudentId(studentId: string, online: boolean): Promise<IMemorizationSession[]> {
    try {
      return await this.baseService.authGet<IMemorizationSession[]>(
        `${url}${bookMemorizationSessionByStudentId}/${studentId}/${online}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async assignMemorizationSessionToTeacher(memorizationSessionId: string, teacherId: string): Promise<IMemorizationSession[]> {
    try {
      return await this.baseService.authGet<IMemorizationSession[]>(
        `${url}${assignMemorizationSessionToTeacher}/${memorizationSessionId}/${teacherId}`
      );
    } catch (error: any) {
      return error;
    }
  }

  public async addMemorizationSessionBackToQueueAsync(memorizationSessionId: string): Promise<IMemorizationSession[]> {
    try {
      return await this.baseService.authGet<IMemorizationSession[]>(
        `${url}${addMemorizationSessionBackToQueue}/${memorizationSessionId}`
      );
    } catch (error: any) {
      return error;
    }
  }
  
  public async completeMemorizationSession(completeMemorizationSessionRequest: ICompleteMemorizationSessionRequest): Promise<IMemorizationSession[]> {
    try {
      return await this.baseService.authPost<ICompleteMemorizationSessionRequest, IMemorizationSession[]>(
        `${url}${completeMemorizationSession}`,
        completeMemorizationSessionRequest
      );
    } catch (error: any) {
      return error;
    }
  }

  public async deleteMemorizationSession(memorizationSessionId: string): Promise<IMemorizationSession[]> {
    try {
      return await this.baseService.authGet<IMemorizationSession[]>(
        `${url}${deleteMemorizationSession}/${memorizationSessionId}`
      );
    } catch (error: any) {
      return error;
    }
  }

}
